import jsyaml from 'js-yaml';

export const saveConfig = async (config) => {
    const userToken = JSON.parse(localStorage.getItem('userToken')).token;
    const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
    const configName = 'Verify_Module';
    const yamlContent = jsyaml.dump(config);

    try {
        const response = await fetch('https://ayodev.de:8443/api/saveConfig', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify({ botToken, configName, fileContent: yamlContent })
        });

        if (response.ok) {
            console.log('Configuration saved successfully');
        } else {
            console.error('Failed to save configuration');
        }
    } catch (error) {
        console.error('Error saving configuration:', error);
    }
};

export const fetchConfig = async () => {
    const configName = 'Verify_Module';
    const userToken = JSON.parse(localStorage.getItem('userToken')).token;
    const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;

    try {
        const response = await fetch('https://ayodev.de:8443/api/getConfig', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            body: JSON.stringify({ botToken, configName })
        });

        if (response.ok) {
            const data = await response.json();
            return(jsyaml.load(data.fileContent));
        } else {
            console.error('Failed to fetch configuration');
        }
    } catch (error) {
        console.error('Error fetching configuration:', error);
    }
};

export const handleSuggestions = (inputValue) => {
    const suggestionsList = [
        '%member%', '%point%', '%boosts%', '%level%', '%xp%', '%role%', '%server%',
        '%channel%', '%status%', '%rank%', '%title%', '%item%', '%score%'
    ];
    const lastWord = inputValue.split(' ').pop();
    if (lastWord.startsWith('%') && !lastWord.includes('%', 1)) {
        return suggestionsList.filter(s => s.startsWith(lastWord));
    }
    return [];
};

export const renderEmbedPreview = (embedData) => {
    return (
        <div className="embed-preview" style={{ borderLeft: `4px solid ${embedData.color || '#2c3e50'}` }}>
            {(embedData.author || embedData.authorIcon) && (
                <div className="embed-author">
                    {embedData.authorIcon && (
                        <img src={embedData.authorIcon} className="embed-author-icon" alt="Author Icon" />
                    )}
                    {embedData.author && (
                        <span className="embed-author-text">{embedData.author}</span>
                    )}
                </div>
            )}
            {embedData.title && <h1 className="embed-title">{embedData.title}</h1>}
            {embedData.description && <p className="embed-description">{embedData.description}</p>}
            {embedData.image && <img src={embedData.image} className="embed-image" alt="Embed Image" />}
            {(embedData.footer || embedData.footerIcon || embedData.timestamp) && (
                <div className="embed-footer">
                    {embedData.footerIcon && (
                        <img src={embedData.footerIcon} className="embed-footer-icon" alt="Footer Icon" />
                    )}
                    {embedData.footer && <span className="embed-footer-text">{embedData.footer}</span>}
                    {embedData.footer && embedData.timestamp && <span className="footer-separation-mark"> · </span>}
                    {embedData.timestamp && (
                        <span className="embed-timestamp">{new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                    )}
                </div>
            )}
        </div>
    );
};
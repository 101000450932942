import React, { useRef, useState } from 'react';
import './BotInfo.css';
import RestartButton from './RestartButton';
import Permissions from './Permissions';

const BotInfo = () => {
    const bot = JSON.parse(localStorage.getItem('currentBot'));
    const bannerInputRef = useRef(null);
    const avatarInputRef = useRef(null);
    const [bannerUrl, setBannerUrl] = useState(bot.banner_url);
    const [avatarUrl, setAvatarUrl] = useState(bot.avatar_url);
    const [showNamePopup, setShowNamePopup] = useState(false);
    const [newName, setNewName] = useState('');
    const [showPermissions, setShowPermissions] = useState(false);

    const handleBannerUploadClick = () => {
        bannerInputRef.current.click();
    };

    const handleAvatarUploadClick = () => {
        avatarInputRef.current.click();
    };

    const uploadFile = async (file, reason) => {
        if (file.size > 10 * 1024 * 1024) {
            alert('File size exceeds 10 MB');
            return;
        }

        const validFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!validFileTypes.includes(file.type)) {
            alert('Only image files (JPG, PNG, GIF) are allowed');
            return;
        }

        try {
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                const base64data = reader.result.split(',')[1];

                const response = await fetch('https://ayodev.de:8443/api/picture', { 
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    }, 
                    body: JSON.stringify({ botToken: botToken, file: base64data, reason: reason })
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data.url);
                    if (reason === 'banner') {
                        setBannerUrl(data.url);
                    } else if (reason === 'avatar') {
                        setAvatarUrl(data.url);
                    }
                }
            };
        } catch(error) {
            console.error('Error:', error);
        }
    };

    const handleBannerChange = async (e) => {
        console.log('Banner wird geändert');
        const file = e.target.files[0];
        uploadFile(file, 'banner');
    };
    
    const handleAvatarChange = async (e) => {
        console.log('Avatar wird geändert');
        const file = e.target.files[0];
        uploadFile(file, 'avatar');
    };

    const handleNameChangeClick = () => {
        setShowNamePopup(true);
    };

    const handleNameChange = async () => {
        try {
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
    
            const response = await fetch('https://ayodev.de:8443/api/changeName', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
                body: JSON.stringify({ botToken: botToken, newName: newName })
            });
    
            if (response.ok) {
                const updatedBot = { ...bot, bot_name: newName };
                localStorage.setItem('currentBot', JSON.stringify(updatedBot));
                setShowNamePopup(false);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="bot-info-container">
            <div className="image-container">
                <img className="dashboard-bot-bot-banner" src={bannerUrl || 'https://placehold.co/600x200'} alt="Bot Banner" />
                <div className="image-upload-overlay" onClick={handleBannerUploadClick}>
                    <span className="edit-icon">✎</span>
                </div>
                <input
                    type="file"
                    ref={bannerInputRef}
                    className="file-input"
                    onChange={handleBannerChange}
                />
            </div>

            <div className="bot-info-second">
                <div className="avatar-container">
                    <img className="dashboard-bot-bot-avatar" src={avatarUrl || 'https://placehold.co/200x200'} alt="Bot Avatar" />
                    <div className="image-upload-overlay image-upload-translate" onClick={handleAvatarUploadClick}>
                        <span className="edit-icon">✎</span>
                    </div>
                    <input
                        type="file"
                        ref={avatarInputRef}
                        className="file-input"
                        onChange={handleAvatarChange}
                    />
                </div>
                <div className="bot-info-header">
                    <h1 className="dashboard-bot-bot-name">{bot.bot_name} <span onClick={handleNameChangeClick} className="bot-name-change-icon">✎</span></h1>
                    <div className="bot-info-actions desktop-actions">
                        <RestartButton />
                        <button onClick={() => setShowPermissions(true)}>Show Permissions</button>
                    </div>
                </div>
                <h3 className="dashboard-bot-bot-id">Bot ID: {bot.UUID}</h3>
                <div className="bot-info-actions mobile-actions">
                    <RestartButton />
                    <button onClick={() => setShowPermissions(true)}>Show Permissions</button>
                </div>
            </div>

            {showNamePopup && (
                <div className="name-popup-overlay" onClick={() => setShowNamePopup(false)}>
                    <div className="name-popup-content" onClick={e => e.stopPropagation()}>
                        <h2>Bot-Namen ändern</h2>
                        <input
                            type="text"
                            className="name-input"
                            placeholder="Neuer Bot-Name"
                            value={newName}
                            onChange={e => setNewName(e.target.value)}
                        />
                        <button onClick={handleNameChange}>Ändern</button>
                    </div>
                </div>
            )}

            {showPermissions && (
                <Permissions show={showPermissions} onClose={() => setShowPermissions(false)} />
            )}
        </div>
    );
};

export default BotInfo;
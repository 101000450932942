import React, { useState, useEffect, useRef } from 'react';
import EmojiSelector from '../components/EmojiSelector';
import jsyaml from 'js-yaml';

const AutoCompleteTextbox = ({ keyProp, value, labelParse, handleInputChange }) => {
    const [inputValue, setInputValue] = useState(value);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const suggestionsRef = useRef(null);
    const inputRef = useRef(null);

    // Test-Vorschläge für Autocomplete
    const suggestionsList = [
        '%member%', '%point%', '%boosts%', '%level%', '%xp%', '%role%', '%server%',
        '%channel%', '%status%', '%rank%', '%title%', '%item%', '%score%'
    ];

    const handleChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        handleInputChange(keyProp, newValue);

        // Das zuletzt eingegebene Wort extrahieren
        const lastWord = newValue.split(' ').pop();

        // Prüfen, ob das letzte Wort ein unvollständiger Platzhalter ist (mit `%` beginnend)
        if (lastWord.startsWith('%') && !lastWord.includes('%', 1)) {
            const filteredSuggestions = suggestionsList.filter((s) =>
                s.startsWith(lastWord)
            );
            setSuggestions(filteredSuggestions);
            setShowSuggestions(filteredSuggestions.length > 0);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        const words = inputValue.split(' ');
        words[words.length - 1] = suggestion;
        const newInputValue = words.join(' ');
        setInputValue(newInputValue);
        handleInputChange(keyProp, newInputValue);
        setShowSuggestions(false);
    };

    const handleKeyDown = (e) => {
        if (showSuggestions) {
            if (e.key === 'ArrowDown') {
                setActiveSuggestion((prev) => {
                    const nextIndex = (prev + 1) % suggestions.length;
                    scrollToActiveSuggestion(nextIndex);
                    return nextIndex;
                });
            } else if (e.key === 'ArrowUp') {
                setActiveSuggestion((prev) => {
                    const nextIndex = (prev - 1 + suggestions.length) % suggestions.length;
                    scrollToActiveSuggestion(nextIndex);
                    return nextIndex;
                });
            } else if (e.key === 'Enter' || e.key === 'Tab') {
                e.preventDefault();
                handleSuggestionClick(suggestions[activeSuggestion]);
                setActiveSuggestion(0);
            }
        }
    };

    const scrollToActiveSuggestion = (index) => {
        if (suggestionsRef.current) {
            const activeItem = suggestionsRef.current.children[index];
            if (activeItem) {
                activeItem.scrollIntoView({
                    block: 'nearest',
                    behavior: 'smooth'
                });
            }
        }
    };

    // Vorschlagsliste schließen, wenn die Textbox den Fokus verliert
    const handleBlur = () => {
        setShowSuggestions(false);
    };

    useEffect(() => {
        if (!showSuggestions) {
            setActiveSuggestion(0);
        }
    }, [showSuggestions]);

    return (
        <div className={`config-item config-item-${keyProp}`} key={keyProp}>
            <label className={`config-key config-key-${keyProp}`} htmlFor={keyProp}>
                {labelParse(keyProp)}
            </label>
            <div className="config-key-row">
                <div className="text-suggestion-div">
                    <input
                        type="text"
                        id={keyProp}
                        className="config-value"
                        value={value}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        ref={inputRef}
                    />
                    {showSuggestions && (
                        <ul className={`suggestions-list ${keyProp.includes('(textFieldEmoji)') ? 'suggestion-list-shorter' : ''}`} ref={suggestionsRef}>
                            {suggestions.map((suggestion, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleSuggestionClick(suggestion)}
                                    className={`suggestion-item ${index === activeSuggestion ? 'active' : ''}`}
                                >
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                {keyProp.includes('(textFieldEmoji)') && <EmojiSelector id={keyProp} value={inputValue} />}
            </div>
        </div>
    );
};

const AddListTextbox = ({ keyProp, value, handleAdd, handleRemove, placeholder, label }) => {
    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState(value || []);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleAddClick = () => {
        if (inputValue.trim() !== '') {
            handleAdd(keyProp, inputValue);
            setItems([...items, inputValue]);
            setInputValue('');
        }
    };

    const handleRemoveClick = (item) => {
        handleRemove(keyProp, item);
        setItems(items.filter(i => i !== item));
    };

    return (
        <div className="config-item">
            <div className="config-key-row">
                <label className="config-key" htmlFor={keyProp}>{label}</label>
                <input
                    type="text"
                    id={keyProp}
                    className="config-value"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                />
                <button className="btn btn-primary" onClick={handleAddClick}>Add</button>
            </div>
            <ul className="list-group">
                {items.map((item, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        <span className="list-item-text">{item}</span>
                        <button className="btn btn-danger btn-sm" onClick={() => handleRemoveClick(item)}>Remove</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export function modifyYamlContentPingRoles(yamlContent, eventName, action) {
    const yamlObj = jsyaml.load(yamlContent);

    if (action === 'add') {
        if (!yamlObj['pingroles(addList)']) {
            yamlObj['pingroles(addList)'] = [];
        }
        yamlObj['pingroles(addList)'].push(eventName);

        const newEventStructure = {
            [`divider${Object.keys(yamlObj).length + 1}`]: `${eventName} Section`,
            [`${eventName}_role_id_permission(RoleID)`]: "",
            [`divider${Object.keys(yamlObj).length + 2}`]: "",
            [`${eventName}_emoji_button(EmojiID)`]: "",
            [`divider${Object.keys(yamlObj).length + 3}`]: "",
            [`${eventName}_type_button(selectInput-primary-secondary-success-danger)`]: "secondary"
        };

        Object.assign(yamlObj, newEventStructure);
    } else if (action === 'remove') {
        if (yamlObj['pingroles(addList)']) {
            yamlObj['pingroles(addList)'] = yamlObj['pingroles(addList)'].filter(event => event !== eventName);
        }

        const keysToRemove = [
            `${eventName}_role_id_permission(RoleID)`,
            `${eventName}_emoji_button(EmojiID)`,
            `${eventName}_type_button(selectInput-primary-secondary-success-danger)`
        ];

        keysToRemove.forEach(key => {
            const index = Object.keys(yamlObj).indexOf(key);
            if (index !== -1) {
            const nextKey = Object.keys(yamlObj)[index + 1];
            const keybefore = Object.keys(yamlObj)[index - 1];
            console.log(key, nextKey, keybefore);
            if (nextKey && nextKey.startsWith('divider')) {
                console.log('deleting', nextKey);
                delete yamlObj[nextKey];
            }
            if (keybefore && keybefore.startsWith('divider') && index === Object.keys(yamlObj).length - 1) {
                console.log('deleting', keybefore);
                delete yamlObj[keybefore];
            }
            console.log('deleting', key);
            delete yamlObj[key];
            }
        });
    }

    return jsyaml.dump(yamlObj).replaceAll("'", '"');
}

export function modifyYamlContentTicketSystem(yamlContent, eventName, action) {
    const yamlObj = jsyaml.load(yamlContent);

    if (action === 'add') {
        if (!yamlObj['reasons(addList)']) {
            yamlObj['reasons(addList)'] = [];
        }
        if (!yamlObj['reasons(addList)'].includes(eventName)) {
            yamlObj['reasons(addList)'].push(eventName);
        }

        const newEventStructure = {
            [`divider${Object.keys(yamlObj).length + 1}`]: `${eventName} Category`,
            [`${eventName}_Emoji_Dropdown(EmojiID)`]: "emoji10",
            [`divider${Object.keys(yamlObj).length + 2}`]: "",
            [`${eventName}_role_id_permission(RoleID_List)`]: [""],
            [`divider${Object.keys(yamlObj).length + 3}`]: "",
            [`${eventName}_role_id_delete_permission(RoleID_List)`]: [""],
            [`divider${Object.keys(yamlObj).length + 4}`]: "",
            [`${eventName}_channel_name(textField)`]: `┃🎫・${eventName.toLowerCase()}-%name%`,
            [`divider${Object.keys(yamlObj).length + 5}`]: "",
            [`${eventName}_category_id(CategoryID)`]: "",
            [`divider${Object.keys(yamlObj).length + 6}`]: "",
            [`${eventName}_close_category_id(CategoryID)`]: "",
            [`divider${Object.keys(yamlObj).length + 7}`]: "",
            [`${eventName}_message_content(textField)`]: "%user_mention%",
            [`divider${Object.keys(yamlObj).length + 8}`]: "",
            [`${eventName}_max_tickets(textField)`]: "3",
            [`divider${Object.keys(yamlObj).length + 9}`]: "",
            [`${eventName}_button_close_name(textField)`]: "Schließen",
            [`${eventName}_button_close_emojiid(EmojiID)`]: "emoji26",
            [`divider${Object.keys(yamlObj).length + 10}`]: "",
            [`${eventName}_button_open_name(textField)`]: "Öffnen",
            [`${eventName}_button_open_emojiid(EmojiID)`]: "emoji19",
            [`divider${Object.keys(yamlObj).length + 11}`]: "",
            [`${eventName}_button_delete_name(textField)`]: "Löschen",
            [`${eventName}_button_delete_emojiid(EmojiID)`]: "emoji5",
            [`divider${Object.keys(yamlObj).length + 12}`]: "",
            [`${eventName}_embed_title(textField)`]: `%emoji29% **${eventName} Ticket #%ticket_nummer%**`,
            [`${eventName}_embed_description(textField)`]: `%emoji2% **Hallo, %user%!**\n\n Bitte habe einen Moment **Geduld**, unser %team_role% wird sich in **Kürze** um dich kümmern. \n\nAnliegen - **${eventName}** \nErstellt - **%created_ticket%**\nID - \`#%id%\`\nNummer - **#%ticket_nummer%**\n\n%emoji2% Falls es doch ein Grund für den **Voice Support** ist, bitte melde dich im **Warteraum**.`,
            [`${eventName}_embed_authoricon(textField)`]: "https://i.imgur.com/C40PDBj.gif",
            [`${eventName}_embed_author(textField)`]: "AyoBots",
            [`${eventName}_embed_image(textField)`]: "https://i.imgur.com/vKNtaOV.png",
            [`${eventName}_embed_color(hexColorPicker)`]: "#ffa500",
            [`${eventName}_embed_footer(textField)`]: "© Copyright 2024 | AyoBots",
            [`${eventName}_embed_footerIcon(textField)`]: "https://i.imgur.com/C40PDBj.gif",
            [`${eventName}_embed_timestamp(toggleSwitch)`]: false,
            [`divider${Object.keys(yamlObj).length + 13}`]: "",
            [`${eventName}_close_embed_title(textField)`]: "%emoji26% **Dieses Ticket wurde geschlossen.**",
            [`${eventName}_close_embed_description(textField)`]: "%emoji2% %member% hat das **Ticket** (`#%ticket_id%`) %time% geschlossen\n\n %emoji26% __**Channel Rechte**__ \n\n %users% \n\n %emoji11% Es kann nun **niemand** mehr im **Ticket** schreiben.",
            [`${eventName}_close_embed_authoricon(textField)`]: "https://i.imgur.com/C40PDBj.gif",
            [`${eventName}_close_embed_author(textField)`]: "AyoBots",
            [`${eventName}_close_embed_image(textField)`]: "",
            [`${eventName}_close_embed_color(hexColorPicker)`]: "#FF0000",
            [`${eventName}_close_embed_footer(textField)`]: "",
            [`${eventName}_close_embed_footerIcon(textField)`]: "",
            [`${eventName}_close_embed_timestamp(toggleSwitch)`]: false,
            [`divider${Object.keys(yamlObj).length + 14}`]: "",
            [`${eventName}_open_embed_title(textField)`]: "%emoji2% **Dieses Ticket wurde geöffnet.**",
            [`${eventName}_open_embed_description(textField)`]: "%emoji2% %member% hat das **Ticket** (`#%ticket_id%`) %time% geöffnet\n\n %emoji27% __**Channel Rechte**__ \n\n %users% \n\n %emoji19% Es kann **nun** wieder im **Ticket** geschrieben **werden**.",
            [`${eventName}_open_embed_authoricon(textField)`]: "https://i.imgur.com/C40PDBj.gif",
            [`${eventName}_open_embed_author(textField)`]: "AyoBots",
            [`${eventName}_open_embed_image(textField)`]: "",
            [`${eventName}_open_embed_color(hexColorPicker)`]: "#32CD32",
            [`${eventName}_open_embed_footer(textField)`]: "",
            [`${eventName}_open_embed_footerIcon(textField)`]: "",
            [`${eventName}_open_embed_timestamp(toggleSwitch)`]: false,
            [`divider${Object.keys(yamlObj).length + 15}`]: "",
            [`${eventName}_delete_embed_title(textField)`]: "%emoji5% Ticket (`#%id%`) wurde gelöscht.",
            [`${eventName}_delete_embed_description(textField)`]: "%emoji1% Das **Ticket** wurde **gelöscht**. \n\n%emoji2% Ticket - %channel% (%mention_channel%)\n%emoji7% ChannelID - `%channel_id%`\n%emoji17% TicketID - `#%id%`\n%emoji8% Ticketnummer - `#%ticket_nummer%` \n%emoji4% Owner - %owner_mention% (`%owner%`) \n%emoji10% Von - %deleter%\n%emoji16% Anliegen -  **%reason%** \n%emoji16% Nachrichten -  **%messages_size% Nachrichten** \n%emoji27% Zugriff:\n %channel_access% \n\n Das **Transcript** ist oben **angehangen**.",
            [`${eventName}_delete_embed_authoricon(textField)`]: "https://i.imgur.com/C40PDBj.gif",
            [`${eventName}_delete_embed_author(textField)`]: "AyoBots",
            [`${eventName}_delete_embed_image(textField)`]: "",
            [`${eventName}_delete_embed_color(hexColorPicker)`]: "#ff8a00",
            [`${eventName}_delete_embed_footer(textField)`]: "",
            [`${eventName}_delete_embed_footerIcon(textField)`]: "",
            [`${eventName}_delete_embed_timestamp(toggleSwitch)`]: false
        };

        Object.assign(yamlObj, newEventStructure);
    } else if (action === 'remove') {
        if (yamlObj['reasons(addList)']) {
            yamlObj['reasons(addList)'] = yamlObj['reasons(addList)'].filter(reason => reason !== eventName);
        }

        const keysToRemove = [
            `${eventName}_Emoji_Dropdown(EmojiID)`,
            `${eventName}_role_id_permission(RoleID_List)`,
            `${eventName}_role_id_delete_permission(RoleID_List)`,
            `${eventName}_channel_name(textField)`,
            `${eventName}_category_id(CategoryID)`,
            `${eventName}_close_category_id(CategoryID)`,
            `${eventName}_message_content(textField)`,
            `${eventName}_max_tickets(textField)`,
            `${eventName}_button_close_name(textField)`,
            `${eventName}_button_close_emojiid(EmojiID)`,
            `${eventName}_button_open_name(textField)`,
            `${eventName}_button_open_emojiid(EmojiID)`,
            `${eventName}_button_delete_name(textField)`,
            `${eventName}_button_delete_emojiid(EmojiID)`,
            `${eventName}_embed_title(textField)`,
            `${eventName}_embed_description(textField)`,
            `${eventName}_embed_authoricon(textField)`,
            `${eventName}_embed_author(textField)`,
            `${eventName}_embed_image(textField)`,
            `${eventName}_embed_color(hexColorPicker)`,
            `${eventName}_embed_footer(textField)`,
            `${eventName}_embed_footerIcon(textField)`,
            `${eventName}_embed_timestamp(toggleSwitch)`,
            `${eventName}_close_embed_title(textField)`,
            `${eventName}_close_embed_description(textField)`,
            `${eventName}_close_embed_authoricon(textField)`,
            `${eventName}_close_embed_author(textField)`,
            `${eventName}_close_embed_image(textField)`,
            `${eventName}_close_embed_color(hexColorPicker)`,
            `${eventName}_close_embed_footer(textField)`,
            `${eventName}_close_embed_footerIcon(textField)`,
            `${eventName}_close_embed_timestamp(toggleSwitch)`,
            `${eventName}_open_embed_title(textField)`,
            `${eventName}_open_embed_description(textField)`,
            `${eventName}_open_embed_authoricon(textField)`,
            `${eventName}_open_embed_author(textField)`,
            `${eventName}_open_embed_image(textField)`,
            `${eventName}_open_embed_color(hexColorPicker)`,
            `${eventName}_open_embed_footer(textField)`,
            `${eventName}_open_embed_footerIcon(textField)`,
            `${eventName}_open_embed_timestamp(toggleSwitch)`,
            `${eventName}_delete_embed_title(textField)`,
            `${eventName}_delete_embed_description(textField)`,
            `${eventName}_delete_embed_authoricon(textField)`,
            `${eventName}_delete_embed_author(textField)`,
            `${eventName}_delete_embed_image(textField)`,
            `${eventName}_delete_embed_color(hexColorPicker)`,
            `${eventName}_delete_embed_footer(textField)`,
            `${eventName}_delete_embed_footerIcon(textField)`,
            `${eventName}_delete_embed_timestamp(toggleSwitch)`
        ];

        keysToRemove.forEach(key => {
            const index = Object.keys(yamlObj).indexOf(key);
            if (index !== -1) {
            const nextKey = Object.keys(yamlObj)[index + 1];
            const keybefore = Object.keys(yamlObj)[index - 1];
            console.log(key, nextKey, keybefore);
            if (nextKey && nextKey.startsWith('divider')) {
                console.log('deleting', nextKey);
                delete yamlObj[nextKey];
            }
            if (keybefore && keybefore.startsWith('divider') && index === Object.keys(yamlObj).length - 1) {
                console.log('deleting', keybefore);
                delete yamlObj[keybefore];
            }
            console.log('deleting', key);
            delete yamlObj[key];
            }
        });
    }

    return jsyaml.dump(yamlObj);
}

export function modifyYamlContentChannelStickyMessage(yamlContent, eventName, action) {
    const yamlObj = jsyaml.load(yamlContent);

    if (action === 'add') {
        if (!yamlObj['channel(addList)']) {
            yamlObj['channel(addList)'] = [];
        }
        if (!yamlObj['channel(addList)'].includes(eventName)) {
            yamlObj['channel(addList)'].push(eventName);
        }

        const newEventStructure = {
            [`divider${Object.keys(yamlObj).length + 1}`]: `${eventName} Section`,
            [`${eventName}_channel_stickymessage(ChannelID_List)`]: ["1270163459966763069"],
            [`divider${Object.keys(yamlObj).length + 2}`]: "",
            [`${eventName}_message_normal(textField)`]: "Hier was reinschreiben und beim Embed alles leer machen, damit nur eine normale Nachricht geschrieben wird",
            [`divider${Object.keys(yamlObj).length + 3}`]: "",
            [`${eventName}_embed_title(textFieldEmoji)`]: "",
            [`${eventName}_embed_description(textFieldEmoji)`]: "Sticky Message Embed",
            [`${eventName}_embed_authoricon(textField)`]: "",
            [`${eventName}_embed_author(textFieldEmoji)`]: "",
            [`${eventName}_embed_image(textField)`]: "",
            [`${eventName}_embed_color(hexColorPicker)`]: "#000040",
            [`${eventName}_embed_footer(textFieldEmoji)`]: "",
            [`${eventName}_embed_footerIcon(textField)`]: "",
            [`${eventName}_embed_timestamp(toggleSwitch)`]: false
        };

        Object.assign(yamlObj, newEventStructure);
    } else if (action === 'remove') {
        if (yamlObj['channel(addList)']) {
            yamlObj['channel(addList)'] = yamlObj['channel(addList)'].filter(channel => channel !== eventName);
        }

        const keysToRemove = [
            `${eventName}_channel_stickymessage(ChannelID_List)`,
            `${eventName}_message_normal(textField)`,
            `${eventName}_embed_title(textFieldEmoji)`,
            `${eventName}_embed_description(textFieldEmoji)`,
            `${eventName}_embed_authoricon(textField)`,
            `${eventName}_embed_author(textFieldEmoji)`,
            `${eventName}_embed_image(textField)`,
            `${eventName}_embed_color(hexColorPicker)`,
            `${eventName}_embed_footer(textFieldEmoji)`,
            `${eventName}_embed_footerIcon(textField)`,
            `${eventName}_embed_timestamp(toggleSwitch)`
        ];

        keysToRemove.forEach(key => {
            const index = Object.keys(yamlObj).indexOf(key);
            if (index !== -1) {
                const nextKey = Object.keys(yamlObj)[index + 1];
                const keybefore = Object.keys(yamlObj)[index - 1];
                if (nextKey && nextKey.startsWith('divider')) {
                    delete yamlObj[nextKey];
                }
                if (keybefore && keybefore.startsWith('divider') && index === Object.keys(yamlObj).length - 1) {
                    delete yamlObj[keybefore];
                }
                delete yamlObj[key];
            }
        });
    }

    return jsyaml.dump(yamlObj);
}

export default AutoCompleteTextbox;
export { AddListTextbox };
import React from 'react';
import './CardsSection.css';
import ServerBild from '../assets/images/bildServer.png';
import DownTimeBild from '../assets/images/downtime.png';
import Bild1 from '../assets/images/1bild.png';
import Bild2 from '../assets/images/2bild.png';
import Bild3 from '../assets/images/3bild.png';
import Bild4 from '../assets/images/4bild.png';
import Bild5 from '../assets/images/5bild.png';
import ErrorBild from '../assets/images/error.png';
import MainBild from '../assets/images/mainBild.png';
import SettingsBild from '../assets/images/settings.png';

function CardsSection() {
    const cardData = [
        { id: 1, title: "Bot Hosting", description: "Alle Bots werden von uns gehostet, die Kosten sind im Preis enthalten.", imageUrl: ServerBild },
        { id: 2, title: "Downtime?", description: "Wir versuchen die Downtime so klein wie möglich zu halten, um ein maximal störungsfreies Erlebnis zu bieten.", imageUrl: DownTimeBild },
        { id: 3, title: "Einfache Einrichtung", description: "Unsere Bots sind einfach einzurichten und zu konfigurieren.", imageUrl: Bild1 },
        { id: 4, title: "Zuverlässiger Support", description: "Unser Support-Team steht dir jederzeit zur Verfügung.", imageUrl: Bild2 },
        { id: 5, title: "Regelmäßige Updates", description: "Wir bieten regelmäßige Updates, um die Funktionalität zu verbessern.", imageUrl: Bild3 },
        { id: 6, title: "Benutzerfreundlich", description: "Unsere Bots sind benutzerfreundlich und einfach zu bedienen.", imageUrl: Bild4 },
        { id: 7, title: "Vielfältige Funktionen", description: "Unsere Bots bieten eine Vielzahl von Funktionen, um deinen Bedürfnissen gerecht zu werden.", imageUrl: Bild5 },
        { id: 8, title: "Fehlerbehebung", description: "Wir helfen dir bei der Behebung von Fehlern und Problemen.", imageUrl: ErrorBild },
        { id: 9, title: "Hauptfunktionen", description: "Entdecke die Hauptfunktionen unserer Bots.", imageUrl: MainBild },
        { id: 10, title: "Einstellungen", description: "Passe die Einstellungen deiner Bots nach deinen Wünschen an.", imageUrl: SettingsBild },
    ];

    return (
        <section className="cards-section">
            {cardData.map(card => (
                <div key={card.id} className="card">
                    <div className="card-inner">
                        <div className="card-front">
                            <img src={card.imageUrl} alt={card.title} className="card-image" />
                        </div>
                        <div className="card-back">
                            <h3>{card.title}</h3>
                            <p>{card.description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </section>
    );
}

export default CardsSection;
import React from 'react';
import './TosPage.css';

const TermsOfService = () => {
  return (
    <div className="tos-container">
      <h1 className="tos-title">Terms of Service</h1>
      
      <h2 className="tos-subheading">1. Geltungsbereich</h2>
      <p className="tos-info">
        Diese Nutzungsbedingungen gelten für alle Dienstleistungen, die auf der Webseite ayobots.de angeboten werden, einschließlich der kostenpflichtigen Dienste und der Nutzung der Bots.
      </p>

      <h2 className="tos-subheading">2. Vertragsschluss</h2>
      <p className="tos-info">
        Durch die Nutzung dieser Webseite erklären Sie sich mit diesen Nutzungsbedingungen einverstanden. Ein Vertrag zwischen Ihnen und dem Betreiber dieser Webseite kommt mit der Nutzung der angebotenen Dienstleistungen zustande.
      </p>

      <h2 className="tos-subheading">3. Leistungen und Kosten</h2>
      <p className="tos-info">
        Der Betreiber bietet kostenpflichtige Dienste im "Pre-Paid"-Modell an. Nutzer können Zeitguthaben für Bots aufladen. Wenn das Guthaben abläuft, wird der Bot deaktiviert. Eine automatische Verlängerung im Sinne eines Abonnements erfolgt nicht.
      </p>

      <h2 className="tos-subheading">4. Reselling-Verbot</h2>
      <p className="tos-info">
        Das Weiterverkaufen von Bots oder Dienstleistungen an Dritte (Reselling) ist ohne ausdrückliche schriftliche Genehmigung untersagt. Verstöße gegen dieses Verbot führen zur sofortigen Suspendierung aller Produkte und Dienste, ohne Anspruch auf finanzielle Kompensation.
      </p>

      <h2 className="tos-subheading">5. Haftungsausschluss</h2>
      <p className="tos-info">
        Der Betreiber haftet nicht für Schäden, die durch die Nutzung der Bots oder der Webseite entstehen, es sei denn, diese Schäden wurden durch vorsätzliches oder grob fahrlässiges Verhalten des Betreibers verursacht. Dies gilt insbesondere für Schäden, die durch Fehlfunktionen von Bots entstehen, sofern diese nicht auf bewusstes Handeln unsererseits zurückzuführen sind.
      </p>

      <h2 className="tos-subheading">6. Urheberrecht und Schutz der Bots</h2>
      <p className="tos-info">
        Alle auf dieser Webseite bereitgestellten Inhalte, einschließlich des Quellcodes, der Bilder und anderer Materialien, unterliegen dem Urheberrecht des Betreibers. Dies gilt auch für die von uns bereitgestellten Bots. Die Nutzer haben die Möglichkeit, eigene Inhalte wie Bot-Profilbilder hinzuzufügen. Diese Inhalte bleiben im Besitz der jeweiligen Nutzer.
      </p>

      <h2 className="tos-subheading">7. Datenspeicherung und API-Nutzung</h2>
      <p className="tos-info">
        Um die Funktion der Webseite und der Bots zu gewährleisten, speichern wir bestimmte Daten der Nutzer. Diese Daten werden ausschließlich für die Funktionsfähigkeit der Dienste verwendet und nicht an Dritte verkauft oder weitergegeben. Einige Daten werden an interne APIs weitergeleitet, diese dienen jedoch nur der Funktionalität und teilen keine Informationen mit Dritten.
      </p>

      <h2 className="tos-subheading">8. Schutz vor Angriffen und Manipulation</h2>
      <p className="tos-info">
        Jeglicher Versuch, die Webseite, die Bots oder die API-Schnittstellen zu manipulieren oder anzugreifen, wird strengstens verfolgt. Bei Verdacht auf solche Handlungen behalten wir uns das Recht vor, betroffene Produkte sofort zu suspendieren und den Nutzer von der weiteren Nutzung der Dienste auszuschließen.
      </p>

      <h2 className="tos-subheading">9. Änderungen der ToS</h2>
      <p className="tos-info">
        Der Betreiber behält sich das Recht vor, diese Nutzungsbedingungen jederzeit zu ändern. Änderungen werden auf der Webseite bekannt gegeben und treten sofort in Kraft.
      </p>
    </div>
  );
};

export default TermsOfService;

import React, { useEffect, useState, useRef } from 'react';
import AutoCompleteTextbox, { AddListTextbox } from '../ConfigPageUtils';
import { saveConfig, fetchConfig, renderEmbedPreview, handleSuggestions } from './helpers/utils';
import './Verify_Module_Page.css';
import './helpers/assistant.css';

const VerifyModulePage = () => {
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(true);
    const [collapsedSections, setCollapsedSections] = useState({
        role_add_embed: true,
    });
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const suggestionsRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            setConfig(await fetchConfig());
            setLoading(false);
        };
        fetchData();
    }, []);

    const handleInputChange = (key, value, checkSuggestions = false) => {
        setConfig(prevConfig => ({
            ...prevConfig,
            [key]: value
        }));

        if (checkSuggestions) {
            const filteredSuggestions = handleSuggestions(value);
            setSuggestions(filteredSuggestions);
            setShowSuggestions(filteredSuggestions.length > 0);
        }
    };

    const handleSuggestionClick = (key, suggestion) => {
        const words = config[key].split(' ');
        words[words.length - 1] = suggestion;
        const newValue = words.join(' ');
        handleInputChange(key, newValue);
        setShowSuggestions(false);
    };

    const handleKeyDown = (e, key) => {
        if (showSuggestions) {
            if (e.key === 'ArrowDown') {
                setActiveSuggestion((prev) => {
                    const nextIndex = (prev + 1) % suggestions.length;
                    scrollToActiveSuggestion(nextIndex);
                    return nextIndex;
                });
            } else if (e.key === 'ArrowUp') {
                setActiveSuggestion((prev) => {
                    const nextIndex = (prev - 1 + suggestions.length) % suggestions.length;
                    scrollToActiveSuggestion(nextIndex);
                    return nextIndex;
                });
            } else if (e.key === 'Enter' || e.key === 'Tab') {
                e.preventDefault();
                handleSuggestionClick(key, suggestions[activeSuggestion]);
                setActiveSuggestion(0);
            }
        }
    };

    const scrollToActiveSuggestion = (index) => {
        if (suggestionsRef.current) {
            const activeItem = suggestionsRef.current.children[index];
            if (activeItem) {
                activeItem.scrollIntoView({
                    block: 'nearest',
                    behavior: 'smooth'
                });
            }
        }
    };

    const handleBlur = () => {
        setShowSuggestions(false);
    };

    const toggleSection = (section) => {
        setCollapsedSections(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    };

    const handleSaveConfig = () => {
        saveConfig(config);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!config) {
        return <div>Error loading configuration</div>;
    }

    return (
        <div className="Verify_Module_config-page">
            <div className="Verify_Module_config-header">
                <h2>Verify Module Configuration</h2>
                <button onClick={handleSaveConfig}>Save</button>
            </div>
            <div className="Verify_Module_config-item">
                <div className="Verify_Module_switch-container">
                    <label>Module aktiviert</label>
                    <label className="Verify_Module_switch">
                        <input
                            type="checkbox"
                            checked={config['module_aktiviert(toggleSwitch)']}
                            onChange={e => handleInputChange('module_aktiviert(toggleSwitch)', e.target.checked)}
                        />
                        <span className="Verify_Module_slider"></span>
                    </label>
                </div>
            </div>
            <div className="Verify_Module_config-item">
                <label>Role ID on Verify</label>
                <AddListTextbox
                    keyProp="role_id_on_verify(RoleID_List)"
                    value={config['role_id_on_verify(RoleID_List)']}
                    handleAdd={(key, value) => handleInputChange(key, [...config[key], value])}
                    handleRemove={(key, value) => handleInputChange(key, config[key].filter(item => item !== value))}
                    placeholder="Add Role ID"
                    label="Role IDs"
                />
            </div>
            <div className="Verify_Module_config-item">
                <div className="Verify_Module_switch-container">
                    <label>Verify Link Button</label>
                    <label className="Verify_Module_switch">
                        <input
                            type="checkbox"
                            checked={config['verify_link_button(toggleSwitch)']}
                            onChange={e => handleInputChange('verify_link_button(toggleSwitch)', e.target.checked)}
                        />
                        <span className="Verify_Module_slider"></span>
                    </label>
                </div>
            </div>
            <div className="Verify_Module_config-item">
                <label>Verify Link URL</label>
                <input
                    type="text"
                    value={config['verify_link_url(textField)']}
                    onChange={e => handleInputChange('verify_link_url(textField)', e.target.value)}
                />
            </div>
            <div className="Verify_Module_config-item">
                <label>Verify Link Name</label>
                <input
                    type="text"
                    value={config['verify_link_name(textField)']}
                    onChange={e => handleInputChange('verify_link_name(textField)', e.target.value)}
                />
            </div>
            <div className="Verify_Module_config-item">
                <div className="Verify_Module_switch-container">
                    <label>Verify Normal Button</label>
                    <label className="Verify_Module_switch">
                        <input
                            type="checkbox"
                            checked={config['verify_normal_button(toggleSwitch)']}
                            onChange={e => handleInputChange('verify_normal_button(toggleSwitch)', e.target.checked)}
                        />
                        <span className="Verify_Module_slider"></span>
                    </label>
                </div>
            </div>
            <div className="Verify_Module_config-item">
                <label>Verify Button Name</label>
                <input
                    type="text"
                    value={config['verify_button_name(textField)']}
                    onChange={e => handleInputChange('verify_button_name(textField)', e.target.value, true)}
                    onKeyDown={e => handleKeyDown(e, 'verify_button_name(textField)')}
                    onBlur={handleBlur}
                />
                {showSuggestions && (
                    <ul className="new-suggestions-list" ref={suggestionsRef}>
                        {suggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                onClick={() => handleSuggestionClick('verify_button_name(textField)', suggestion)}
                                className={`new-suggestion-item ${index === activeSuggestion ? 'active' : ''}`}
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="Verify_Module_config-item">
                <label>Verify Button Emoji</label>
                <input
                    type="text"
                    value={config['verify_button_emoji(EmojiID)']}
                    onChange={e => handleInputChange('verify_button_emoji(EmojiID)', e.target.value)}
                />
            </div>
            <div className="Verify_Module_config-item">
                <div className="Verify_Module_switch-container">
                    <label>Verify Count Button</label>
                    <label className="Verify_Module_switch">
                        <input
                            type="checkbox"
                            checked={config['verify_count_button(toggleSwitch)']}
                            onChange={e => handleInputChange('verify_count_button(toggleSwitch)', e.target.checked)}
                        />
                        <span className="Verify_Module_slider"></span>
                    </label>
                </div>
            </div>
            <div className="Verify_Module_config-item">
                <label onClick={() => toggleSection('role_add_embed')} className="Verify_Module_collapsible-section">
                    Role Add Embed {collapsedSections['role_add_embed'] ? '▼' : '▲'}
                </label>
                {!collapsedSections['role_add_embed'] && (
                    <>
                        <div className="Verify_Module_config-row">
                            <div className="Verify_Module_config-item-row">
                                <div className="Verify_Module_config-item">
                                    <label>Role Add Embed Title</label>
                                    <input
                                        type="text"
                                        value={config['role_add_embed_title(textFieldEmoji)']}
                                        onChange={e => handleInputChange('role_add_embed_title(textFieldEmoji)', e.target.value)}
                                    />
                                </div>
                                <div className="Verify_Module_config-item">
                                    <label>Role Add Embed Description</label>
                                    <input
                                        type="text"
                                        value={config['role_add_embed_description(textFieldEmoji)']}
                                        onChange={e => handleInputChange('role_add_embed_description(textFieldEmoji)', e.target.value)}
                                    />
                                </div>
                                <div className="Verify_Module_config-item">
                                    <label>Role Add Embed Author Icon</label>
                                    <input
                                        type="text"
                                        value={config['role_add_embed_authoricon(textField)']}
                                        onChange={e => handleInputChange('role_add_embed_authoricon(textField)', e.target.value)}
                                    />
                                </div>
                                <div className="Verify_Module_config-item">
                                    <label>Role Add Embed Author</label>
                                    <input
                                        type="text"
                                        value={config['role_add_embed_author(textFieldEmoji)']}
                                        onChange={e => handleInputChange('role_add_embed_author(textFieldEmoji)', e.target.value)}
                                    />
                                </div>
                                <div className="Verify_Module_config-item">
                                    <label>Role Add Embed Image</label>
                                    <input
                                        type="text"
                                        value={config['role_add_embed_image(textField)']}
                                        onChange={e => handleInputChange('role_add_embed_image(textField)', e.target.value)}
                                    />
                                </div>
                                <div className="Verify_Module_config-item">
                                    <label>Role Add Embed Color</label>
                                    <input
                                        type="color"
                                        value={config['role_add_embed_color(hexColorPicker)']}
                                        onChange={e => handleInputChange('role_add_embed_color(hexColorPicker)', e.target.value)}
                                    />
                                </div>
                                <div className="Verify_Module_config-item">
                                    <label>Role Add Embed Footer</label>
                                    <input
                                        type="text"
                                        value={config['role_add_embed_footer(textFieldEmoji)']}
                                        onChange={e => handleInputChange('role_add_embed_footer(textFieldEmoji)', e.target.value)}
                                    />
                                </div>
                                <div className="Verify_Module_config-item">
                                    <label>Role Add Embed Footer Icon</label>
                                    <input
                                        type="text"
                                        value={config['role_add_embed_footerIcon(textField)']}
                                        onChange={e => handleInputChange('role_add_embed_footerIcon(textField)', e.target.value)}
                                    />
                                </div>
                                <div className="Verify_Module_config-item">
                                    <div className="Verify_Module_switch-container">
                                        <label>Role Add Embed Timestamp</label>
                                        <label className="Verify_Module_switch">
                                            <input
                                                type="checkbox"
                                                checked={config['role_add_embed_timestamp(toggleSwitch)']}
                                                onChange={e => handleInputChange('role_add_embed_timestamp(toggleSwitch)', e.target.checked)}
                                            />
                                            <span className="Verify_Module_slider"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="Verify_Module_config-item-row">
                                <div className="Verify_Module_config-item">
                                    {renderEmbedPreview({
                                        title: config['role_add_embed_title(textFieldEmoji)'],
                                        description: config['role_add_embed_description(textFieldEmoji)'],
                                        authorIcon: config['role_add_embed_authoricon(textField)'],
                                        author: config['role_add_embed_author(textFieldEmoji)'],
                                        image: config['role_add_embed_image(textField)'],
                                        color: config['role_add_embed_color(hexColorPicker)'],
                                        footer: config['role_add_embed_footer(textFieldEmoji)'],
                                        footerIcon: config['role_add_embed_footerIcon(textField)'],
                                        timestamp: config['role_add_embed_timestamp(toggleSwitch)']
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {/* Add more fields as needed */}
        </div>
    );
};

export default VerifyModulePage;

import React, { useEffect, useState } from 'react';
import './RegisterdPage.css';

function RegisterdPage() {
    const [loading, setLoading] = useState(true);
    const [botAvatar, setBotAvatar] = useState('https://placehold.co/400x400/png');
    const [botName, setBotName] = useState('Verification Page');
    const [registeredDescription, setRegisteredDescription] = useState('');
    const [verified, setVerified] = useState(false);
    const [inviteLink, setInviteLink] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');
        const botId = state;

        if (code && botId) {
            fetch('https://ayodev.de:8443/ayocore/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: code,
                    botId: botId,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    const alias = localStorage.getItem('alias');
                    console.log('Alias:', alias);
                    console.log('Response from backend:', data);
                    fetch(`https://ayodev.de:8443/ayocore/returnRegisterInfos?alias=${alias}`)
                        .then(response => response.json())
                        .then(info => {
                            setBotAvatar(info.Picture || 'https://placehold.co/400x400/png');
                            setBotName(info.Name || 'Verification Page');
                            setRegisteredDescription(info.RegisteredBeschreibung || '');
                            setInviteLink(info.ReturnLink || '#');
                            setVerified(true);

                            // Store necessary data in sessionStorage
                            sessionStorage.setItem('registerd-botAvatar', info.Picture || 'https://placehold.co/400x400/png');
                            sessionStorage.setItem('registerd-botName', info.Name || 'Verification Page');
                            sessionStorage.setItem('registerd-registeredDescription', info.RegisteredBeschreibung || '');
                            sessionStorage.setItem('registerd-inviteLink', info.ReturnLink || '#');
                            sessionStorage.setItem('registerd-verified', 'true');

                            // Clear URL parameters
                            window.history.replaceState({}, document.title, window.location.pathname);
                        })
                        .catch(error => console.error('Error fetching bot info:', error));
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    setLoading(false);
                });
        } else {
            // Load data from sessionStorage if available
            const storedBotAvatar = sessionStorage.getItem('registerd-botAvatar');
            const storedBotName = sessionStorage.getItem('registerd-botName');
            const storedRegisteredDescription = sessionStorage.getItem('registerd-registeredDescription');
            const storedInviteLink = sessionStorage.getItem('registerd-inviteLink');
            const storedVerified = sessionStorage.getItem('registerd-verified');

            if (storedBotAvatar && storedBotName && storedRegisteredDescription && storedInviteLink && storedVerified) {
                setBotAvatar(storedBotAvatar);
                setBotName(storedBotName);
                setRegisteredDescription(storedRegisteredDescription);
                setInviteLink(storedInviteLink);
                setVerified(storedVerified === 'true');
                setLoading(false);
            }
        }

        // Clear sessionStorage when the page is closed
        return () => {
            sessionStorage.removeItem('registerd-botAvatar');
            sessionStorage.removeItem('registerd-botName');
            sessionStorage.removeItem('registerd-registeredDescription');
            sessionStorage.removeItem('registerd-inviteLink');
            sessionStorage.removeItem('registerd-verified');
        };
    }, []);

    if (loading) {
        return (
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="RegisterdPage">
            <div className="profile">
                <img src={botAvatar} alt="Profile" />
                <div className="profile-name">
                    <h1>{botName}</h1>
                </div>
                <p>{registeredDescription}</p>
            </div>
            {verified ? (
                <>
                    <a href={inviteLink}>
                        <button>Done</button>
                    </a>
                </>
            ) : (
                <p>Processing verification...</p>
            )}
        </div>
    );
}

export default RegisterdPage;

import React from 'react';
import './ImpressumPage.css';

const Impressum = () => {
  return (
    <div className="impressum-container">
      <h1 className="impressum-title">Impressum</h1>
      <p className="impressum-subtitle">Angaben gemäß § 5 TMG:</p>
      
      <p className="impressum-info">
        Max Mustermann <br />
        Musterstraße 1 <br />
        12345 Musterstadt
      </p>

      <h2 className="impressum-subheading">Kontakt</h2>
      <p className="impressum-info">
        Telefon: +49 123 456789 <br />
        E-Mail: info@musterfirma.de
      </p>

      <h2 className="impressum-subheading">Umsatzsteuer</h2>
      <p className="impressum-info">
        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE123456789
      </p>

      <h2 className="impressum-subheading">Haftung für Inhalte</h2>
      <p className="impressum-info">
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. 
        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen 
        oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
      </p>

      <h2 className="impressum-subheading">Haftung für Links</h2>
      <p className="impressum-info">
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte 
        auch keine Gewähr übernehmen.
      </p>

      <h2 className="impressum-subheading">Urheberrecht</h2>
      <p className="impressum-info">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
      </p>
    </div>
  );
};

export default Impressum;

import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import StatsCarousel from '../components/StatsCarousel';
import CardsSection from '../components/CardsSection';
import Footer from '../components/Footer';

function App() {
    return (
        <div className="App">
            <StatsCarousel />
            <Header />
            <HeroSection />
            <CardsSection />
            <Footer />
        </div>
    );
}

export default App;

import React, { useEffect, useState } from 'react';
import './ConfigPage.css';
import jsyaml from 'js-yaml';
import EmojiPicker from '../components/EmojiPicker';
import AutoCompleteTextbox, { modifyYamlContentPingRoles, modifyYamlContentTicketSystem, AddListTextbox } from './ConfigPageUtils';
import { func } from 'prop-types';

const ConfigPage = () => {
    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState(null);
    const [showResetPopup, setShowResetPopup] = useState(false);

    const handleResetConfig = () => {
        setShowResetPopup(true);
    };

    const confirmResetConfig = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('userToken')).token;
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
            const urlParams = new URLSearchParams(window.location.search);
            const configName = urlParams.get('config');

            const response = await fetch('https://ayodev.de:8443/api/resetConfig', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ botToken, configName })
            });

            if (response.ok) {
                const { config } = await response.json();
                localStorage.setItem('currentConfig', config);
                window.location.reload();
            } else {
                console.error('Failed to reset configuration');
            }
        } catch (error) {
            console.error('Error resetting configuration:', error);
        }
    };

    const closeResetPopup = () => {
        setShowResetPopup(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const configName = urlParams.get('config');
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;

            if (configName) {
                try {
                    const response = await fetch('https://ayodev.de:8443/api/getConfig', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${userToken}`
                        },
                        body: JSON.stringify({ botToken, configName })
                    });

                    if (response.ok) {
                        const data = await response.json();
                        localStorage.setItem('currentConfig', data.fileContent);
                        setConfig(data.fileContent);
                    } else {
                        if (response.status === 403) {
                            localStorage.clear();
                            window.location.href = '/login';
                        }
                        console.error('Failed to fetch data from API');
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        try {
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;

            const fetchData = async () => {
            const response = await fetch('https://ayodev.de:8443/api/validity', {
                method: 'POST',
                headers: {
                'Authorization': `Bearer ${userToken}`
                }
            });

            if (!response.ok) {
                localStorage.clear();
                window.location.href = '/';
            }
            };

            fetchData();
        } catch (error) {
            console.error('Failed to fetch data from API', error);
            localStorage.clear();
            window.location.href = '/';
        }
    }, []);

    const handleAddPingRole = async (key, eventName) => {
        const updatedConfig = modifyYamlContentPingRoles(config, eventName, 'add');
        setConfig(updatedConfig);
    };

    const handleRemovePingRole = async (key, eventName) => {
        const updatedConfig = modifyYamlContentPingRoles(config, eventName, 'remove');
        setConfig(updatedConfig);
    };

    const handleAddTicketReason = async (key, eventName) => {
        const updatedConfig = modifyYamlContentTicketSystem(config, eventName, 'add');
        setConfig(updatedConfig);
    };

    const handleRemoveTicketReason = async (key, eventName) => {
        const updatedConfig = modifyYamlContentTicketSystem(config, eventName, 'remove');
        setConfig(updatedConfig);
    };

    const saveConfig = async () => {
        const userToken = JSON.parse(localStorage.getItem('userToken')).token;
        const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
        const configName = new URLSearchParams(window.location.search).get('config');
        const yamlContent = jsyaml.safeDump(jsyaml.safeLoad(config));
    
        try {
            const response = await fetch('https://ayodev.de:8443/api/saveConfig', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
                body: JSON.stringify({ botToken, configName, fileContent: yamlContent })
            });
    
            if (response.ok) {
                console.log('Configuration saved successfully');
                localStorage.setItem('currentConfig', yamlContent);
                document.getElementById('button-bar').style.display = 'none';
            } else {
                console.error('Failed to save configuration');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    let isSaving = false;
    document.addEventListener("keydown", function(event) {
        if (event.ctrlKey && event.key === "s") { 
            event.preventDefault();
            if (!isSaving) {
                isSaving = true;
                
                if (document.getElementById('button-bar').style.display === 'flex') {
                    document.getElementById('save-button').click();
                }

                setTimeout(() => {
                    isSaving = false;
                }, 300);
            }
        }
    });
    
    function labelParse(text) {
        var parsedText = text;
        if (typeof parsedText === 'string') {
            parsedText = parsedText.replaceAll('_', ' ');
            parsedText = parsedText.charAt(0).toUpperCase() + parsedText.slice(1);
            parsedText = parsedText.replace(/\(.*?\)/g, '');
        }
        return parsedText;
    }

    function RenderConfig() {
        const configObj = jsyaml.safeLoad(config);
        const configKeys = Object.keys(configObj);
        const localStorageData = JSON.parse(localStorage.getItem('botDetails'))[0];
    
        let dividers = [];
        let currentDivider = null;
        let embedData = {};
    
        const handleInputChange = async (key, value) => {
            configObj[key] = value;
            setConfig(jsyaml.safeDump(configObj));
            
            document.getElementById('button-bar').style.display = 'flex';
        };

        window.editConfig = handleInputChange;

        function embedPreviewParse(text, nl = '') {
            let parsedText = text;

            if (nl === 'nl') {
                parsedText = parsedText.replace(/\\n/g, '<br>');
            }

            parsedText = parsedText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            parsedText = parsedText.replace(/\*(.*?)\*/g, '<em>$1</em>');
            parsedText = parsedText.replace(/__(.*?)__/g, '<u>$1</u>');
            parsedText = parsedText.replace(/~~(.*?)~~/g, '<s>$1</s>');
            parsedText = parsedText.replace(/`(.*?)`/g, '<code>$1</code>');
            parsedText = parsedText.replace(/```(.*?)```/gs, '<pre><code>$1</code></pre>');

            const emojiMap = {
                '%emoji1%': '<:emoji1:1261386278688260278>',
                '%emoji2%': '<:emoji2:1261386280269385924>',
                '%emoji3%': '<:emoji3:1261386282354085909>',
                '%emoji4%': '<:emoji4:1261386284212027584>',
                '%emoji5%': '<:emoji5:1261386285910851604>',
                '%emoji6%': '<:emoji6:1261386287726727339>',
                '%emoji7%': '<:emoji7:1261386289404448788>',
                '%emoji8%': '<:emoji8:1261386291254394951>',
                '%emoji9%': '<:emoji9:1261386294001668169>',
                '%emoji10%': '<:emoji10:1261386295712940063>',
                '%emoji11%': '<:emoji11:1261656480097112087>',
                '%emoji12%': '<:emoji12:1261656481653198859>',
                '%emoji13%': '<:emoji13:1261656483393830912>',
                '%emoji14%': '<:emoji14:1261656485604360213>',
                '%emoji15%': '<:emoji15:1261656487025967154>',
                '%emoji16%': '<:emoji16:1261656488590577706>',
                '%emoji17%': '<:emoji17:1261656490335273001>',
                '%emoji18%': '<:emoji18:1261656492206198804>',
                '%emoji19%': '<:emoji19:1261386313161248843>',
                '%emoji20%': '<:emoji20:1261386314809479189>',
                '%emoji21%': '<a:emoji21:1261656497348411462>',
                '%emoji22%': '<:emoji22:1261386324674609153>',
                '%emoji23%': '<:emoji23:1261386326796669099>',
                '%emoji24%': '<:emoji24:1261386328545824858>',
                '%emoji25%': '<:emoji25:1261386330018152489>',
                '%emoji26%': '<:emoji26:1261386332618494032>',
                '%emoji27%': '<:emoji27:1261656506906968074>',
                '%emoji28%': '<:emoji28:1261656507880050770>',
                '%emoji29%': '<:emoji29:1261656509587394661>',
                '%emoji30%': '<:emoji30:1261656510904270911>',
                '%emoji31%': '<:emoji31:1261656512770740307>',
            };

            Object.keys(emojiMap).forEach(key => {
                parsedText = parsedText.replace(new RegExp(key, 'g'), emojiMap[key]);
            });

            parsedText = parsedText.replace(/<a?:(.*?):(\d+)>/g, (match, p1, p2) => {
                const isAnimated = match.startsWith('<a:');
                const extension = isAnimated ? 'gif' : 'png';
                return `<img src="https://cdn.discordapp.com/emojis/${p2}.${extension}" alt="${p1}" class="discord-emoji" />`;
            });

            return parsedText;
        }
    
        const renderEmbedPreview = () => (
            <div className="preview-container-inside" style={{ borderLeft: `4px solid ${embedData.embed_color || '#2c3e50'}` }}>
                {(embedData.embed_author || embedData.embed_authoricon) && (
                    <div className="bot-config-author-container">
                        {embedData.embed_authoricon && (
                            <img src={embedData.embed_authoricon} className="bot-config-embed_authoricon" alt="Author Icon" />
                        )}
                        {embedData.embed_author && (
                            <span className="bot-config-embed_author">{embedData.embed_author}</span>
                        )}
                    </div>
                )}
                {embedData.embed_title && <h1 className="bot-config-embed_title" dangerouslySetInnerHTML={{ __html: embedPreviewParse(embedData.embed_title) }}></h1>}
                {embedData.embed_description && <p className="bot-config-embed_description" dangerouslySetInnerHTML={{ __html: embedPreviewParse(embedData.embed_description, 'nl') }}></p>}
                {embedData.embed_image && <img src={embedData.embed_image} className="bot-config-embed_image" alt="Embed Image" />}
                {(embedData.embed_footer || embedData.embed_footerIcon || embedData.embed_timestamp) && (
                    <div className="bot-config-footer-container">
                        {embedData.embed_footerIcon && (
                            <img src={embedData.embed_footerIcon} className="bot-config-embed_footerIcon" alt="Footer Icon" />
                        )}
                        {embedData.embed_footer && <span className="bot-config-embed_footer" dangerouslySetInnerHTML={{ __html: embedPreviewParse(embedData.embed_footer) }}></span>}
                        {embedData.embed_footer && embedData.embed_timestamp && <span className="footer-seperation-mark"> · </span>}
                        {embedData.embed_timestamp && (
                            <span className="bot-config-embed_timestamp">{new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                        )}
                    </div>
                )}
            </div>
        );
    
        function labelParse(text) {
            var parsedText = text;
            if (typeof parsedText === 'string') {
                parsedText = parsedText.replaceAll('_', ' ');
                parsedText = parsedText.charAt(0).toUpperCase() + parsedText.slice(1);
                parsedText = parsedText.replace(/\(.*?\)/g, '');
            }
            return parsedText;
        }

        configKeys.forEach((key) => {
            var value = configObj[key];
            if (typeof value === 'string') {
                value = value.replace(/\n/g, '\\n');
            }
            
            if (key.includes('divider')) {
                if (currentDivider) {
                    if (Object.keys(embedData).length > 0) {
                        currentDivider.preview.push(renderEmbedPreview());
                    }
                    dividers.push(currentDivider);
                }
    
                currentDivider = {
                    items: [],
                    preview: []
                };
                embedData = {};
            } else if (key === "module_aktiviert(toggleSwitch)") {
                const configName = new URLSearchParams(window.location.search).get('config');

                currentDivider.items.push(
                    <div className="config-header">
                        <h2>{labelParse(configName)}</h2>
                        <div className="config-header-controls">
                            <div className="config-module-status-bar">
                                <label>Module aktiviert:</label>
                                <label className="switch">
                                    <input type="checkbox" id={key} className="config-value" defaultChecked={value} onChange={(e) => handleInputChange(key, e.target.checked)} />
                                    <span className="slider"></span>
                                </label>
                            </div>
                            <button className="reset-config-button" onClick={handleResetConfig}>Reset Config</button>
                        </div>
                    </div>
                );
            } else if (currentDivider) {
                if (key.includes('(textField)') || key.includes('(textFieldEmoji)')) {
                    currentDivider.items.push(
                        <AutoCompleteTextbox 
                            key={key}
                            value={value}
                            labelParse={labelParse}
                            handleInputChange={handleInputChange}
                            keyProp={key}
                        />
                    );
                } else if (key.includes('(toggleSwitch)')) {
                    currentDivider.items.push(
                        <div className="config-item config-item-row" key={key}>
                            <label className="config-key" htmlFor={key}>{labelParse(key)}</label>
                            <label className="switch">
                                <input type="checkbox" id={key} className="config-value" defaultChecked={value} onChange={(e) => handleInputChange(key, e.target.checked)} />
                                <span className="slider"></span>
                            </label>
                        </div>
                    );
                } else if (key.includes('(RoleID_List)') || key.includes('(ChannelID_List)') || key.includes('(CategoryID_List)') || key.includes('(VoiceID_List)')) {
                    const options = localStorageData[key.includes('RoleID') ? 'roles' : key.includes('ChannelID')  ? 'channels' : key.includes('CategoryID') ? 'categorys' : 'voicechannels']
                        .split('|bigcut§!')
                        .map(item => {
                            const [name, id] = item.split('|smallcut§!');
                            return <option key={id} value={id}>{name}</option>;
                        });
    
                    currentDivider.items.push(
                        <div className="config-item" key={key}>
                            <label className="config-key" htmlFor={key}>{labelParse(key)}</label>
                            <select id={key} className="config-value" defaultValue={value} onChange={(e) => handleInputChange(key, e.target.value)}>
                                {options}
                            </select>
                        </div>
                    );
                } else if (key.includes('(RoleID)') || key.includes('(ChannelID)') || key.includes('(CategoryID)') || key.includes('(VoiceID)')) {
                    const options = localStorageData[key.includes('RoleID') ? 'roles' : key.includes('ChannelID') ? 'channels' : key.includes('CategoryID') ? 'categorys' : 'voicechannels']
                        .split('|bigcut§!')
                        .map(item => {
                            const [name, id] = item.split('|smallcut§!');
                            return <option key={id} value={id}>{name}</option>;
                        });

                    currentDivider.items.push(
                        <div className="config-item" key={key}>
                            <label className="config-key" htmlFor={key}>{labelParse(key)}</label>
                            <select id={key} className="config-value" defaultValue={value} onChange={(e) => handleInputChange(key, e.target.value)}>
                                {options}
                            </select>
                        </div>
                    );
                } else if (key.includes('(hexColorPicker)')) {
                    currentDivider.items.push(
                        <div className="config-item config-item-row" key={key}>
                            <label className="config-key" htmlFor={key}>{labelParse(key)}</label>
                            <input type="color" id={key} className="config-value" defaultValue={value} onChange={(e) => handleInputChange(key, e.target.value)} />
                        </div>
                    );
                } else if (key.includes('EmojiID')) {
                    currentDivider.items.push(
                        <div className="config-item config-item-row" key={key}>
                            <label className="config-key" htmlFor={key} style={{ transform: 'translateY(5px)' }}>{labelParse(key)}</label>
                            <EmojiPicker id={key} value={value}/>
                        </div>
                    );
                } else if (key.includes('(selectInput-')) {
                    const options = key.match(/\(([^)]+)\)/)[1].split('-').slice(1).map(option => {
                        return <option key={option} value={option}>{option}</option>;
                    });

                    currentDivider.items.push(
                        <div className="config-item" key={key}>
                            <label className="config-key" htmlFor={key}>{labelParse(key)}</label>
                            <select id={key} className="config-value" defaultValue={value} onChange={(e) => handleInputChange(key, e.target.value)}>
                                {options}
                            </select>
                        </div>
                    );
                } else if (key.includes('(addList)')) {
                    currentDivider.items.push(
                        <AddListTextbox
                            key={key}
                            keyProp={key}
                            value={value}
                            handleAdd={key.includes('pingroles') ? handleAddPingRole : handleAddTicketReason}
                            handleRemove={key.includes('pingroles') ? handleRemovePingRole : handleRemoveTicketReason}
                            placeholder={key.includes('pingroles') ? 'Add a ping role' : 'Add a reason'}
                            label={key.includes('pingroles') ? 'Ping Roles' : 'Reasons'}
                        />
                    );
                } else {
                    currentDivider.items.push(
                        <div className="config-item" key={key}>
                            <span className="config-key">{labelParse(key)}</span>: <span className="config-value">{value}</span>
                        </div>
                    );
                }
            }

            if (key.includes('embed_')) {
                const cleanedKey = key.replace(/.*embed_/, 'embed_').replace(/\(.*\)/, '');
                embedData[cleanedKey] = value;
            }
        });
    
        if (currentDivider) {
            if (Object.keys(embedData).length > 0) {
                currentDivider.preview.push(renderEmbedPreview());
            }
            dividers.push(currentDivider);
        }
    
        return (
            <div>
                {dividers.map((dividerObj, index) => (
                    <div key={`divider-${index}`} className="config-section">
                    {dividerObj.divider}
                    <div className="config-items">
                        {dividerObj.items}
                    </div>
                    {dividerObj.preview.length > 0 && (
                        <div className="preview-container">
                            {dividerObj.preview}
                        </div>
                    )}
                    </div>
                ))}
                <div className="button-bar" id="button-bar" style={{ display: 'none'}}>
                    <p className="save-message">Du hast ungespeicherte änderungen</p>
                    <button className="config-save-button" id="save-button" onClick={saveConfig}>Save</button>
                    <button className="config-reset-button" id="reset-button" onClick={() => window.location.reload()}>Reset</button>
                </div>
            </div>
        );
    }    

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('config-popup')) {
            setShowResetPopup(false);
        }
    }

    return (
        <div className="config-page">
            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className="config-container">
                    {RenderConfig()}
                </div>
            )}
            {showResetPopup && (
                <div className="config-popup" onClick={handleOutsideClick}>
                    <div className="config-popup-content">
                        <span className="config-popup-close" onClick={closeResetPopup}>&times;</span>
                        <h3>Confirm Reset</h3>
                        <p>Are you sure you want to reset the configuration to default settings?</p>
                        <button className="confirm-button" onClick={confirmResetConfig}>Yes, Reset</button>
                        <button className="cancel-button" onClick={closeResetPopup}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ConfigPage;
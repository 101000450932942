import React, { useState, useRef, useEffect } from 'react';
import './ConfigList.css';
import { func } from 'prop-types';

const ConfigList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const searchInputRef = useRef(null);
    const configs = JSON.parse(localStorage.getItem('configs'));

    const handleConfigChange = async (configName, isEnabled) => {
        const token = JSON.parse(localStorage.getItem('userToken')).token;
        const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
        const command = `moduleUpdate ${isEnabled ? 'enable' : 'disable'} ${configName.replace(/ /g, '_')}`;

        if (!token || !botToken) {
            console.error('Token or botToken not found in local storage');
            return;
        }
    
        try {
            const response = await fetch('https://ayodev.de:8443/api/sendCommand', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ botToken, command })
            });
    
            if (!response.ok) {
                throw new Error('Failed to send command');
            }
        } catch (error) {
            console.error('Error sending command:', error);
        }
    };

    const configClick = (configName) => {
        if (configName) {
            window.location.href = `https://ayodev.de/dashboard/bot/config/${configName.replace(/ /g, '_')}`;
        }
    };

    const toggleSearch = () => {
        setShowSearch(!showSearch);
        if (!showSearch) {
            setTimeout(() => {
                searchInputRef.current.focus();
            }, 0);
        } else {
            setSearchTerm('');
        }
    };

    const filteredConfigs = configs.filter(config => 
        config.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="dashboard-bot-bot-configs">
            <div className="dashboard-bot-bot-configs-top">
                <h1 className="dashboard-bot-bot-config-header">Bot Configs</h1>
                {showSearch && (
                    <input 
                        type="text" 
                        className="dashboard-bot-bot-config-search-box" 
                        placeholder="Search configs..." 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                        ref={searchInputRef}
                    />
                )}
                <div className="dashboard-bot-bot-config-search" onClick={toggleSearch}>
                    <i className="fas fa-search">
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <circle cx="10" cy="10" r="7" stroke="white" fill="none" stroke-width="2"></circle>
                            <line x1="14" y1="14" x2="20" y2="20" stroke="white" stroke-width="2"></line>
                        </svg>
                    </i>
                </div>
            </div>
            <div className="dashboard-bot-bot-config-cards">
                {filteredConfigs.map((config, index) => (
                    <div key={index} className="dashboard-bot-bot-config-card" onClick={() => configClick(config.name)}>
                        <h2>{config.name}</h2>
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                defaultChecked={config.enabled} 
                                onChange={(e) => handleConfigChange(config.name, e.target.checked)} 
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ConfigList;

import React from 'react';
import { Link } from 'react-router-dom';
import './ErrorPage.css';

const NotFound = () => (
    <div className="notfound-container">
        <h2 className="notfound-title">404 - Seite nicht gefunden</h2>
        <p className="notfound-message">Die angeforderte Seite existiert nicht.</p>
        <Link to="/" className="notfound-link">Zurück zur Startseite</Link>
    </div>
);

export default NotFound;
import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p>© 2024 ayobots.de</p>
      <a href="https://discord.gg/ayobots" className="footer-link" target="_blank" rel="noopener noreferrer">Discord</a>
      <div className="footer-links">
        <a href="/impressum" className="footer-link">Impressum</a> | 
        <a href="/terms-of-service" className="footer-link">AGB / ToS</a> | 
        <a href="https://mc-host24.de/cmp/64056" className="footer-link" target="_blank" rel="noopener noreferrer">Hoster</a>
      </div>
    </footer>
  );
};

export default Footer;
import React from 'react';
import VerifyModulePage from './ConfigPages/Verify_Module_Page';
import JoinLeaveModulePage from './ConfigPages/Join_Leave_Module_Page';

const NewConfigPage = () => {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const configName = pathSegments[pathSegments.length - 1];

    const renderConfigPage = () => {
        console.log('configName:', configName);
        switch (configName) {
            case 'Verify_Module':
                return <VerifyModulePage />;
            case 'Join_Leave_Module':
                return <JoinLeaveModulePage />;
            default:
                return <div>Configuration not found</div>;
        }
    };

    return (
        <div>
            {renderConfigPage()}
        </div>
    );
};

export default NewConfigPage;
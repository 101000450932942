import React, { useState, useEffect } from 'react';
import './DashboardPage.css';
import Footer from '../components/Footer';
import HeadBar from '../components/HeadBar';

const DashboardPage = () => {
    const [loading, setLoading] = useState(true);
    const [storedBots, setStoredBots] = useState([]); 

    localStorage.removeItem('botToken');
    localStorage.removeItem('currentConfig');
    localStorage.removeItem('currentBot');
    localStorage.removeItem('botDetails');
    localStorage.removeItem('botData');
    localStorage.removeItem('configs');

    function checkBotCode() {
        if (localStorage.getItem('botCode')) {
            window.location.href = '/create-bot';
        }
    }

    const reloadBotData = async () => {
        const token = JSON.parse(localStorage.getItem('userToken')).token;
        const botsResponse = await fetch('https://ayodev.de:8443/api/bots', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (botsResponse.ok) {
            const botsData = await botsResponse.json();
            localStorage.setItem('botsData', JSON.stringify(botsData));
            setStoredBots(botsData.botInfo);
        } else {
            if (botsResponse.status === 403) {
                localStorage.clear();
                window.location.href = '/login';
            }
            console.error('Failed to fetch bots data from API');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');

            if (code) {
                try {
                    const response = await fetch('https://ayodev.de:8443/api/verify', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ code })
                    });

                    if (response.ok) {
                        const data = await response.json();
                        localStorage.setItem('userToken', JSON.stringify(data));
                        urlParams.delete('code');
                        window.history.replaceState(null, null, window.location.pathname);

                        const token = data.token;
                        const botsResponse = await fetch('https://ayodev.de:8443/api/bots', {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });

                        if (botsResponse.ok) {
                            const botsData = await botsResponse.json();
                            localStorage.setItem('botsData', JSON.stringify(botsData));
                            setStoredBots(botsData.botInfo);
                        } else {
                            if (response.status === 403) {
                                localStorage.clear();
                                window.location.href = '/login';
                            }
                            console.error('Failed to fetch bots data from API');
                        }
                    } else {
                        console.error('Failed to fetch data from API');
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            } else if (!localStorage.getItem('userToken') || !localStorage.getItem('botsData')) {
                localStorage.clear();
                window.location.href = '/login';
            }
            checkBotCode();

            var flag = false;
            storedBots.forEach(bot => {
                if (bot.UUID === 'awaiting_assignment') {
                    console.log('awaiting_assignment found');
                    flag = true;
                }
            });

            // Remove before release
            const kick = JSON.parse(localStorage.getItem('botsData')).botInfo;
            var flag = false;

            kick.forEach(bot => {
                if (bot.UUID === '0aff06b3') {
                    flag = true;
                }
            });
            
            if (!flag) {
                localStorage.clear();  
                window.location.href = '/';
            } 
            // Remove before release

            setLoading(false);
        };

        if (localStorage.getItem('botsData')) {
            checkBotCode();
            reloadBotData();
            const storedBotsData = JSON.parse(localStorage.getItem('botsData'));
            setStoredBots(storedBotsData.botInfo);
            setLoading(false);
        } else {
            fetchData();
        }
    }, []);

    const handleChooseClick = (botId) => {
        window.location.href = `https://ayodev.de/dashboard/bot?uuid=${botId}`;
    };

    if (loading) {
        return (
            <div className="spinner-container">
                <div className="spinner"></div>  {/* Spinner-Element */}
            </div>
        );
    }

    return (
        <div>
            <HeadBar />
            <div className="dashboard-content">
                {storedBots.map((bot, index) => (
                    bot.UUID !== 'awaiting_assignment' ? (
                        <div key={index} className="dashboard-bot-card">
                            <img className="dashboard-bot-banner" src={bot.banner_url} alt={`${bot.bot_name} banner`} />
                            <div className="dashboard-bot-info">
                                <div className="dashboard-bot-info-top">
                                    <img className="dashboard-bot-avatar" src={bot.avatar_url} alt={`${bot.bot_name} avatar`} />
                                    <div className="dashboard-bot-name-id">
                                        <h2 className="dashboard-bot-name">{bot.bot_name}</h2>
                                        <p className="dashboard-bot-id">UUID: {bot.UUID}</p>
                                    </div>
                                </div>
                                <p className="dashboard-bot-discription">Server: {bot.guild_id}</p>
                                <div className="dashboard-bot-buttons">
                                    <button className="dashboard-choose-button" type="button" onClick={() => handleChooseClick(bot.UUID)}>CHOOSE</button>
                                    <button className="dashboard-options-button" type="button">⋮</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div key={index} className="dashboard-bot-card awaiting-assignment">
                            <img className="dashboard-bot-banner" src={bot.banner_url || 'https://placehold.co/600x200'} alt="Awaiting Assignment banner" />
                            <div className="dashboard-bot-info">
                                <div className="dashboard-bot-info-top">
                                    <img className="dashboard-bot-avatar" src={bot.avatar_url || 'https://placehold.co/200x200'} alt="Awaiting Assignment avatar" />
                                    <div className="dashboard-bot-name-id">
                                        <h2 className="dashboard-bot-name">{bot.bot_name}</h2>
                                    </div>
                                </div>
                                <p className="dashboard-bot-discription">Der Bot wird erstellt gedulde dich bitte ein paar Minuten...</p>
                                <button className="dashboard-refresh-button" type="button" onClick={() => reloadBotData()}>⟳</button>
                            </div>
                        </div>
                    )
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default DashboardPage;
import React, { useState, useEffect, useRef } from 'react';
import emojiData from '../assets/emojis.json';
import './EmojiPicker.css';

const EmojiPicker = ({ id, value }) => {
    const [emojis, setEmojis] = useState([]);
    const [filteredEmojis, setFilteredEmojis] = useState([]);
    const [selectedEmoji, setSelectedEmoji] = useState(value);
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Lade die Standard-Emojis
        const standardEmojis = emojiData.emojis;

        // Lade die benutzerdefinierten Emojis aus dem Local Storage
        const botDetails = JSON.parse(localStorage.getItem('botDetails'));
        const customEmojis = botDetails[0].emotes.split('|bigcut§!').map(emote => {
            const [name, id] = emote.split('|smallcut§!');
            const isAnimated = name.startsWith('<a:');
            const sanitizedId = id.replace(/a/g, '');
            const url = `https://cdn.discordapp.com/emojis/${sanitizedId}.${isAnimated ? 'gif' : 'png'}`;
            return { name, url, originalCode: name };
        });

        // Kombiniere beide Emoji-Quellen, wobei die benutzerdefinierten Emojis oben sind
        const combinedEmojis = [...customEmojis, ...standardEmojis];
        setEmojis(combinedEmojis);
        setFilteredEmojis(combinedEmojis);
    }, []);

    const handleEmojiClick = (emoji) => {
        setSelectedEmoji(emoji.unicode || emoji.originalCode || emoji.url);
        setIsPickerVisible(false);
        window.editConfig(id, emoji.unicode || emoji.originalCode || emoji.url);
    };

    const pickerRef = useRef(null);

    const togglePickerVisibility = async (event) => {
        const allPickers = document.querySelectorAll('.emoji-picker-container', 'emoji-selector-container');
        allPickers.forEach(picker => picker.style.display = 'none');

        await setIsPickerVisible(!isPickerVisible);

        if (!isPickerVisible) {
            const buttonRect = event.target.getBoundingClientRect();
            const pickerContainer = pickerRef.current;

            if (pickerContainer) {
                pickerContainer.style.display = 'block';

                if (window.innerWidth <= 768) {
                    pickerContainer.style.top = `${buttonRect.bottom + window.scrollY + 5}px`;
                } else {
                    pickerContainer.style.top = `${buttonRect.bottom + window.scrollY + 5}px`;
                    pickerContainer.style.left = `${buttonRect.left + window.scrollX}px`;
                }
            }
        }
    };

    const handleSearchChange = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = emojis.filter(emoji => 
            emoji.unicode?.includes(term) || 
            emoji.name?.toLowerCase().includes(term) ||
            emoji.shortcodes?.some(shortcode => shortcode.toLowerCase().includes(term)) ||
            emoji.subpage?.toLowerCase().includes(term)
        );
        setFilteredEmojis(filtered);
    };

    return (
        <div>
            <button onClick={togglePickerVisibility} className="emoji-picker-button">
                {selectedEmoji ? (selectedEmoji.includes('http') ? <img src={selectedEmoji} alt="Selected Emoji" className="selected-emoji-image" /> : selectedEmoji) : '😀'}
            </button>
            {isPickerVisible && (
                <div className="emoji-picker-container" ref={pickerRef}>
                    <div className="emoji-header">
                        <input 
                            type="text" 
                            placeholder="Search emojis..." 
                            value={searchTerm} 
                            onChange={handleSearchChange} 
                            className="emoji-search"
                        />
                        <input
                            type="button"
                            value="X"
                            onClick={togglePickerVisibility}
                            className="emoji-close"
                        />
                    </div>
                    <div className="emoji-list">
                        {filteredEmojis.map((emoji, index) => (
                            <span
                                key={index}
                                className="emoji-item"
                                onClick={() => handleEmojiClick(emoji)}
                            >
                                {emoji.unicode ? emoji.unicode : <img src={emoji.url} alt={emoji.name} className="emoji-image" />}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmojiPicker;
import React, { useEffect, useState, useRef } from 'react';
import './CreateBotPage.css';

const CreateBotPage = () => {
    const [botName, setBotName] = useState('');
    const [guilds, setGuilds] = useState([]);
    const [selectedGuild, setSelectedGuild] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const avatarInputRef = useRef(null);
    const bannerInputRef = useRef(null);

    useEffect(() => {
        const botCode = localStorage.getItem('botCode');
        const userToken = JSON.parse(localStorage.getItem('userToken')).token;
        console.log(userToken)

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                const botCode = localStorage.getItem('botCode');
                const userToken = JSON.parse(localStorage.getItem('userToken')).token;

                if (!userToken) {
                    window.location.href = '/login';
                } else if (!botCode && userToken) {
                    window.location.href = '/dashboard';
                }
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        if (!botCode || !userToken) {
            window.location.href = '/login';
        }

        const fetchGuilds = async () => {
            try {
                const response = await fetch('https://ayodev.de:8443/api/guilds', {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${userToken}` }
                });

                if (response.ok) {
                    const data = await response.json();
                    setGuilds(data.guilds.filter(guild => guild.permissions & 0x20));
                } else {
                    if (response.status === 403) {
                        localStorage.clear();
                        window.location.href = '/login';
                    }
                    console.log('Failed to fetch guilds from API');
                }
            } catch (error) {
                console.error('Failed to fetch guilds from API');
            } finally {
                setLoading(false);
            }
        };

        fetchGuilds();
    }, []);

    const handleCreateBot = async () => {
        const botCode = localStorage.getItem('botCode');
        const userToken = JSON.parse(localStorage.getItem('userToken')).token;

        if (!botName || !selectedGuild) {
            alert('Du musst einen Bot-Namen und eine Gilde auswählen.');
            return;
        }

        const payload = {
            botCode,
            guildId: selectedGuild.id,
            botName,
            avatarUrl: avatarUrl || null,
            bannerUrl: bannerUrl || null
        };

        console.log(userToken, payload);

        try {
            const response = await fetch('https://ayodev.de:8443/api/createBot', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                const inviteLink = data.data;   
                localStorage.removeItem('botCode');
                window.open(inviteLink, '_blank');
            } else {
                alert('Failed to create bot.');
            }
        } catch (error) {
            console.error('Error creating bot:', error);
            alert('Error creating bot.');
        }
    };

    const getGuildIcon = (guild) => {
        if (guild.icon) {
            return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;
        }
        return 'https://avatarfiles.alphacoders.com/367/367017.png';
    };

    const filteredGuilds = guilds
        .filter(guild => guild.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));

    const handleBannerUploadClick = () => {
        bannerInputRef.current.click();
    };

    const handleAvatarUploadClick = () => {
        avatarInputRef.current.click();
    };

    const uploadFile = async (file, reason) => {
        if (file.size > 10 * 1024 * 1024) {
            alert('File size exceeds 10 MB');
            return;
        }

        const validFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!validFileTypes.includes(file.type)) {
            alert('Only image files (JPG, PNG, GIF) are allowed');
            return;
        }

        try {
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;
            const botToken = 'notYetCreatet';

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                const base64data = reader.result.split(',')[1];

                const response = await fetch('https://ayodev.de:8443/api/picture', { 
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    }, 
                    body: JSON.stringify({ botToken: botToken, file: base64data, reason: 'createBot' })
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data.url);
                    if (reason === 'banner') {
                        setBannerUrl(data.url);
                    } else if (reason === 'avatar') {
                        setAvatarUrl(data.url);
                    }
                }
            };
        } catch(error) {
            console.error('Error:', error);
        }
    };

    const handleBannerChange = async (e) => {
        console.log('Banner wird geändert');
        const file = e.target.files[0];
        uploadFile(file, 'banner');
    };
    
    const handleAvatarChange = async (e) => {
        console.log('Avatar wird geändert');
        const file = e.target.files[0];
        uploadFile(file, 'avatar');
    };

    if (loading) {
        return (
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="create-bot-page">
            <div className="image-container">
                <img className="dashboard-bot-bot-banner" src={bannerUrl || 'https://placehold.co/600x200'} alt="Bot Banner" />
                <div className="image-upload-overlay" onClick={handleBannerUploadClick}>
                    <span className="edit-icon">✎</span>
                </div>
                <input
                    type="file"
                    ref={bannerInputRef}
                    className="file-input"
                    onChange={handleBannerChange}
                />
            </div>
            <div className="avatar-container">
                <img className="dashboard-bot-bot-avatar" src={avatarUrl || 'https://placehold.co/200x200'} alt="Bot Avatar" />
                <div className="image-upload-overlay image-upload-translate" onClick={handleAvatarUploadClick}>
                    <span className="edit-icon">✎</span>
                </div>
                <input
                    type="file"
                    ref={avatarInputRef}
                    className="file-input"
                    onChange={handleAvatarChange}
                />
            </div>
            <div className="form-group">
                <label htmlFor="botName">Bot Name:</label>
                <input
                    type="text"
                    id="botName"
                    placeholder="Bot Name..."
                    value={botName}
                    onChange={e => setBotName(e.target.value)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="guildSearch">Select a Guild:</label>
                <input
                    type="text"
                    id="guildSearch"
                    className="search-bar"
                    placeholder="Search guilds..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
                <div className="guild-list">
                    {filteredGuilds.map(guild => (
                        <div
                            className={`guild-item ${selectedGuild && selectedGuild.id === guild.id ? 'selected' : ''}`}
                            key={guild.id}
                            onClick={() => setSelectedGuild(guild)}
                        >
                            <img
                                src={getGuildIcon(guild)}
                                className="guild-icon"
                                alt={`${guild.name} Icon`}
                            />
                            <span>{guild.name}</span>
                        </div>
                    ))}
                </div>
                <p className="disclaimer">
                    <span className="disclaimer-icon">⚠️</span> Hinweis: Der Server wird nur gezeigt wenn du die Berechtigung hast, den Bot einzuladen.
                </p>
            </div>
            <button onClick={handleCreateBot} className="createBotButton">Create Bot</button>
        </div>
    );
};

export default CreateBotPage;
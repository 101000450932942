import React from 'react';
import './Header.css';
import logo from '../assets/images/logo.gif';

const Header = () => {
    return (
        <header className="header">
            <div className="header-content">
                <img src={logo} alt="Logo" className="logo" />
                <h1 className="name">AyoBots</h1>
                <nav className="nav">
                    <a href="/login" className="dashboard-button">Dashboard</a>
                </nav>
            </div>
        </header>
    );
};

export default Header;
import React, { useState, useEffect } from 'react';

const LogoutButton = ({ logout }) => {
    const [url, setUrl] = useState([]); 

    useEffect(() => {
        const fetchData = async () => {
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;
            
            try {
                const response = await fetch('https://ayodev.de:8443/api/avatar', {
                    method: 'POST',
                    headers: { 'Authorization': `Bearer ${userToken}` }
                });

                if (response.ok) {
                    const data = await response.json();
                    setUrl(data.url);
                } else {
                    if (response.status === 403) {
                        localStorage.clear();
                        window.location.href = '/login';
                    } else {
                        console.error('Failed to fetch data from API');
                    }
                }
            } catch (error) {
                console.error('Failed to fetch data from API');
            }
        };

        fetchData();
    }, [logout]);

    function logout() {
        localStorage.clear();
        window.location.href = '/';
    }

    return (
        <button className="dashboard-logout" onClick={logout}>
            <span className="logout-text">Abmelden</span>
            <img
                src={url}
                alt="icon"
                className="logout-icon"
                rel="preload"
            />
        </button>
    );
};

export default LogoutButton;
import React from 'react';

const RestartButton = () => {
    const handleRestart = () => {
        try {
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
            const userToken = JSON.parse(localStorage.getItem('userToken')).token;

            const response = fetch('https://ayodev.de:8443/api/powerAction', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
                body: JSON.stringify({ botToken })
            });

            if (response.ok) {
                console.log('Bot wird neugestartet');
            }
        } catch (error) {
            console.error('Error:', error);        
        }
    };

    return (
        <button onClick={handleRestart}>Bot Neustarten</button>
    );
};

export default RestartButton;

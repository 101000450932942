import React from 'react';
import './HeroSection.css';

function HeroSection() {
    return (
        <section className="hero-section">
            <div className="hero-content">
                <h2>Willkommen bei Ayo Bots</h2>
                <p>Verwalte, erkunde und passe deine Bots ganz einfach an.</p>
                <p>Trete unserer Community bei und erhalte Unterstützung und Updates zu deinen Bots.</p>
                <a href="https://discord.gg/ayobots" className="discord-link" target="_blank" rel="noopener noreferrer">Jetzt unserem Discord beitreten</a>
            </div>
        </section>
    );
}

export default HeroSection;
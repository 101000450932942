import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AyoCore.css';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [popupUser, setPopupUser] = useState(null);
    const [sliderValue, setSliderValue] = useState(0);
    const [selectedCount, setSelectedCount] = useState(0);
    const [customPercentage, setCustomPercentage] = useState(false);
    const [customCount, setCustomCount] = useState(false);
    const [isEditingPercentage, setIsEditingPercentage] = useState(false);
    const [isEditingCount, setIsEditingCount] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [showCreateSelectionPopup, setShowCreateSelectionPopup] = useState(false);
    const [showPullConfirmationPopup, setShowPullConfirmationPopup] = useState(false);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [shakeButton, setShakeButton] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editData, setEditData] = useState({
        UUID: '',
        Name: '',
        Beschreibung: '',
        RegisteredBeschreibung: '',
        ReturnLink: ''
    });
    const [customAlias, setCustomAlias] = useState('');
    const [aliasError, setAliasError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = JSON.parse(localStorage.getItem('userToken')).token;
                const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
                console.log('Token:', token);
                console.log('Bot Token:', botToken);
                const response = await axios.get('https://ayodev.de:8443/ayocore/returnRegistert', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Bot-Token': botToken
                    }
                });
                setUsers(response.data);
                setFilteredUsers(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setFilteredUsers(
            users.filter(user =>
                user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.global_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.discordId.includes(searchTerm)
            )
        );
    }, [searchTerm, users]);

    useEffect(() => {
        setSelectedCount(Math.round((sliderValue / 100) * filteredUsers.length));
    }, [sliderValue, filteredUsers]);

    useEffect(() => {
        const uniqueCountries = [...new Set(users.map(user => user.country))];
        setCountries(uniqueCountries);
    }, [users]);

    useEffect(() => {
        if (selectedCountry) {
            const uniqueRegions = [...new Set(users.filter(user => user.country === selectedCountry).map(user => user.region))];
            setRegions(uniqueRegions);
        } else {
            setRegions([]);
        }
    }, [selectedCountry, users]);

    useEffect(() => {
        applyFilters();
    }, [selectedCountry, selectedRegion, users]);

    const handleCheckboxChange = (userId) => {
        setSelectedUsers(prevSelectedUsers =>
            prevSelectedUsers.includes(userId)
                ? prevSelectedUsers.filter(id => id !== userId)
                : [...prevSelectedUsers, userId]
        );
    };

    const handleUserClick = (user) => {
        setPopupUser(user);
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
        setPopupUser(null);
    };

    const getAvatarUrl = (avatar, userId) => {
        return `https://cdn.discordapp.com/avatars/${userId}/${avatar}.png`;
    };

    const selectRandomUsers = () => {
        const count = selectedCount;
        if (count > filteredUsers.length) {
            console.error('Count exceeds the number of available users');
            return;
        }
        const shuffled = [...filteredUsers].sort(() => 0.5 - Math.random());
        const selected = shuffled.slice(0, count).map(user => user.id);
        setSelectedUsers(selected);
    };

    const handleCustomPercentageChange = (e) => {
        let value = e.target.value.replace(/^0+/, '');
        value = Math.round(value);
        if (value > 100) value = 100;
        setSliderValue(value);
        setCustomPercentage(true);
        setSelectedCount(Math.round((value / 100) * filteredUsers.length)); // Update selected count
    };

    const handleCustomCountChange = (e) => {
        let value = Math.round(e.target.value);
        if (value > filteredUsers.length) value = filteredUsers.length;
        const percentage = Math.round((value / filteredUsers.length) * 100);
        setSliderValue(percentage);
        setCustomCount(true);
        setSelectedCount(value); // Update selected count
    };

    const handlePercentageBlur = () => {
        setIsEditingPercentage(false);
    };

    const handleCountBlur = () => {
        setIsEditingCount(false);
    };

    const handlePercentageKeyPress = (e) => {
        if (e.key === 'Enter') {
            setIsEditingPercentage(false);
        }
    };

    const handleCountKeyPress = (e) => {
        if (e.key === 'Enter') {
            setIsEditingCount(false);
        }
    };

    const handleDelete = async (user) => {
        setUserToDelete(user);
        setShowDeletePopup(true);
    };

    const confirmDelete = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('userToken')).token;
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
            await axios.delete('https://ayodev.de:8443/ayocore/deleteRegistert', {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Bot-Token': botToken
                },
                data: { userId: userToDelete.id }
            });
            setUsers(users.filter(u => u.id !== userToDelete.id));
            setFilteredUsers(filteredUsers.filter(u => u.id !== userToDelete.id));
            setShowDeletePopup(false);
            setUserToDelete(null);
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const closeDeletePopup = () => {
        setShowDeletePopup(false);
        setUserToDelete(null);
    };

    const handlePull = async (user) => {
        try {
            const token = JSON.parse(localStorage.getItem('userToken')).token;
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
            await axios.post('https://ayodev.de:8443/ayocore/pullRegistert', {
                userId: user.id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Bot-Token': botToken
                }
            });
            console.log('User data pulled successfully');
        } catch (error) {
            console.error('Error pulling user data:', error);
        }
    };

    const handlePullSelected = async () => {
        if (selectedUsers.length === 0) {
            console.error('No users selected');
            setShakeButton(true);
            setTimeout(() => setShakeButton(false), 500);
            return;
        }
        setShowPullConfirmationPopup(true);
    };

    const confirmPullSelected = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('userToken')).token;
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
            await axios.post('https://ayodev.de:8443/ayocore/pullRegistert', {
                userId: selectedUsers
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Bot-Token': botToken
                }
            });
            console.log('Selected users pulled successfully');
            setShowPullConfirmationPopup(false);
        } catch (error) {
            console.error('Error pulling selected users:', error);
        }
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('ayoCore-popup')) {
            closePopup();
            closeDeletePopup();
            closeCreateSelectionPopup();
            setShowPullConfirmationPopup(false);
        }
    };

    const handleCreateSelection = () => {
        setShowCreateSelectionPopup(true);
    };

    const closeCreateSelectionPopup = () => {
        setShowCreateSelectionPopup(false);
    };

    const handleSelectInPopup = () => {
        applyFilters(); // Apply filters first
        const count = selectedCount;
        if (count > filteredUsers.length) {
            console.error('Count exceeds the number of available users');
            return;
        }
        const shuffled = [...filteredUsers].sort(() => 0.5 - Math.random());
        const selected = shuffled.slice(0, count).map(user => user.id);
        setSelectedUsers(selected);
        closeCreateSelectionPopup();
    };

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
        setSelectedRegion('');
    };

    const handleRegionChange = (e) => {
        setSelectedRegion(e.target.value);
    };

    const resetFilters = () => {
        setSelectedCountry('');
        setSelectedRegion('');
    };

    const applyFilters = () => {
        let filtered = users;

        if (selectedCountry) {
            filtered = filtered.filter(user => user.country === selectedCountry);
        }

        if (selectedRegion) {
            filtered = filtered.filter(user => user.region === selectedRegion);
        }

        setFilteredUsers(filtered);
        setSelectedCount(Math.round((sliderValue / 100) * filtered.length)); // Update selected count based on filtered users
    };

    const handleEditButtonClick = async () => {
        try {
            const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
            const response = await axios.get(`https://ayodev.de:8443/ayocore/returnRegisterInfos?botToken=${botToken}`);
            setEditData(response.data);
            setShowEditPopup(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleAliasChange = (e) => {
        const value = e.target.value;
        const regex = /^[a-zA-Z0-9-_]{0,20}$/;
        if (regex.test(value)) {
            setCustomAlias(value);
            setAliasError('');
        } else {
            setAliasError('Invalid alias. Only alphanumeric characters, hyphens, and underscores are allowed. Max length is 20.');
        }
    };

    const handleEditSubmit = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('userToken')).token;
            const response = await axios.post('https://ayodev.de:8443/ayocore/changeVerifyData', { ...editData, Alias: customAlias }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.message === 'Alias already exists') {
                setAliasError('Alias already exists. Please choose another one.');
            } else {
                setShowEditPopup(false);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (e.target.classList.contains('ayoCore-popup')) {
                closePopup();
                closeDeletePopup();
                closeCreateSelectionPopup();
                setShowPullConfirmationPopup(false);
                setShowEditPopup(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const fetchEditData = async () => {
            try {
                const botToken = JSON.parse(localStorage.getItem('botToken')).botToken;
                const response = await axios.get(`https://ayodev.de:8443/ayocore/returnRegisterInfos?botToken=${botToken}`);
                setEditData(response.data);
                setCustomAlias(response.data.Alias || '');
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchEditData();
    }, []);

    return (
        <div className="ayoCore-user-list-container" onClick={handleOutsideClick}>
            <h3 className="ayoCore-header">AyoCore Member Backup</h3>
            <input
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="ayoCore-search-input"
            />
            <div className="ayoCore-control-container">
                <div className="ayoCore-add-button-container">
                    <button onClick={handleCreateSelection} className="ayoCore-add-button">Create Selection</button>
                    <button 
                        onClick={handlePullSelected} 
                        className={`ayoCore-add-button ${shakeButton ? 'ayoCore-shake' : ''}`}
                    >
                        Pull Selected Users
                    </button>
                    <button onClick={handleEditButtonClick} className="ayoCore-edit-button">⚙</button>
                </div>
            </div>
            <ul className="ayoCore-user-list">
                {filteredUsers.map(user => (
                    <li key={user.id} className="ayoCore-user-item">
                        <input
                            type="checkbox"
                            checked={selectedUsers.includes(user.id)}
                            onChange={() => handleCheckboxChange(user.id)}
                            className="ayoCore-checkbox"
                        />
                        <img src={getAvatarUrl(user.avatar, user.discordId)} alt="avatar" className="ayoCore-user-avatar" />
                        <div className="ayoCore-user-info" onClick={() => handleUserClick(user)}>
                            <span className="ayoCore-user-global-name">{user.global_name}</span>
                            <span className="ayoCore-user-id">(ID: {user.discordId})</span>
                        </div>
                        <button onClick={() => handleUserClick(user)} className="ayoCore-info-button">Info</button>
                        <button onClick={() => handlePull(user)} className="ayoCore-pull-button">Pull</button>
                        <button onClick={() => handleDelete(user)} className="ayoCore-delete-button">Delete</button>
                    </li>
                ))}
            </ul>

            {showPopup && popupUser && (
                <div className="ayoCore-popup">
                    <div className="ayoCore-popup-content">
                        <span className="ayoCore-close" onClick={closePopup}>&times;</span>
                        <h3>User Details</h3>
                        <img src={getAvatarUrl(popupUser.avatar, popupUser.discordId)} alt="avatar" className="ayoCore-popup-avatar" />
                        <p><strong>Username:</strong> {popupUser.username}</p>
                        <p><strong>Global Name:</strong> {popupUser.global_name}</p>
                        <p><strong>Discord ID:</strong> {popupUser.discordId}</p>
                        <p><strong>Email:</strong> {popupUser.email}</p>
                        <p><strong>IP:</strong> {popupUser.ip}</p>
                        <p><strong>Country:</strong> {popupUser.country}</p>
                        <p><strong>Region:</strong> {popupUser.region}</p>
                        <p><strong>City:</strong> {popupUser.city}</p>
                        <p><strong>Provider:</strong> {popupUser.provider}</p>
                        <p><strong>IP Type:</strong> {popupUser.ip_type}</p>
                        <p><strong>Device:</strong> {popupUser.device}</p>
                        <p><strong>Registered At:</strong> {new Date(popupUser.registered_at).toLocaleString()}</p>
                    </div>
                </div>
            )}

            {showDeletePopup && (
                <div className="ayoCore-popup">
                    <div className="ayoCore-popup-content">
                        <span className="ayoCore-close" onClick={closeDeletePopup}>&times;</span>
                        <h3>Confirm Delete</h3>
                        <p>Are you sure you want to delete this user?</p>
                        <button onClick={confirmDelete} className="ayoCore-delete-button">Yes, Delete</button>
                        <button onClick={closeDeletePopup} className="ayoCore-cancel-button">Cancel</button>
                    </div>
                </div>
            )}

            {showCreateSelectionPopup && (
                <div className="ayoCore-popup">
                    <div className="ayoCore-popup-content ayoCore-popup-content-more-width">
                        <span className="ayoCore-close" onClick={closeCreateSelectionPopup}>&times;</span>
                        <h3>Create Selection</h3>
                        <div className="ayoCore-filter-container ayoCore-filter-container-center">
                            <select value={selectedCountry} onChange={handleCountryChange} className="ayoCore-filter-select">
                                <option value="">Select Country</option>
                                {countries.map(country => (
                                    <option key={country} value={country}>{country}</option>
                                ))}
                            </select>
                            <select value={selectedRegion} onChange={handleRegionChange} className="ayoCore-filter-select">
                                <option value="">Select Region</option>
                                {regions.map(region => (
                                    <option key={region} value={region}>{region}</option>
                                ))}
                            </select>
                            <button onClick={resetFilters} className="ayoCore-filter-button">Reset Filters</button>
                        </div>
                        <div className="ayoCore-slider-container">
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={sliderValue}
                                onChange={(e) => setSliderValue(Math.round(e.target.value))}
                                className="ayoCore-slider"
                            />
                            {isEditingPercentage ? (
                                <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    onChange={handleCustomPercentageChange}
                                    onBlur={handlePercentageBlur}
                                    onKeyPress={handlePercentageKeyPress}
                                    className="ayoCore-custom-input"
                                    autoFocus
                                />
                            ) : (
                                <span className="ayoCore-slider-value" onClick={() => setIsEditingPercentage(true)}>
                                    {sliderValue}% 
                                </span>
                            )}
                            {isEditingCount ? (
                                <input
                                    type="number"
                                    min="0"
                                    max={filteredUsers.length}
                                    value={selectedCount}
                                    onChange={handleCustomCountChange}
                                    onBlur={handleCountBlur}
                                    onKeyPress={handleCountKeyPress}
                                    className="ayoCore-custom-input"
                                    autoFocus
                                />
                            ) : (
                                <span className="ayoCore-slider-value" onClick={() => setIsEditingCount(true)}>
                                    ({selectedCount} accounts)
                                </span>
                            )}
                        </div>
                        <button 
                            onClick={handleSelectInPopup} 
                            className="ayoCore-random-select-button ayoCore-selection"
                        >
                            Select {selectedCount} accounts
                        </button>
                        <button onClick={closeCreateSelectionPopup} className="ayoCore-cancel-button">Close</button>
                    </div>
                </div>
            )}

            {showPullConfirmationPopup && (
                <div className="ayoCore-popup">
                    <div className="ayoCore-popup-content">
                        <span className="ayoCore-close" onClick={() => setShowPullConfirmationPopup(false)}>&times;</span>
                        <h3>Confirm Pull</h3>
                        <p>Are you sure you want to pull {selectedUsers.length} members?</p>
                        <button onClick={confirmPullSelected} className="ayoCore-pull-button">Yes, Pull</button>
                        <button onClick={() => setShowPullConfirmationPopup(false)} className="ayoCore-cancel-button">Cancel</button>
                    </div>
                </div>
            )}

            {showEditPopup && (
                <div className="ayoCore-popup">
                    <div className="ayoCore-popup-content">
                        <span className="ayoCore-close" onClick={() => setShowEditPopup(false)}>&times;</span>
                        <h3>Edit Data</h3>
                        <label className="ayoCore-label">Name</label>
                        <input
                            type="text"
                            name="Name"
                            value={editData.Name}
                            onChange={handleEditChange}
                            className="ayoCore-input"
                        />
                        <label className="ayoCore-label">Beschreibung</label>
                        <input
                            type="text"
                            name="Beschreibung"
                            value={editData.Beschreibung}
                            onChange={handleEditChange}
                            className="ayoCore-input"
                        />
                        <label className="ayoCore-label">Registered Beschreibung</label>
                        <input
                            type="text"
                            name="RegisteredBeschreibung"
                            value={editData.RegisteredBeschreibung}
                            onChange={handleEditChange}
                            className="ayoCore-input"
                        />
                        <label className="ayoCore-label">Return Link</label>
                        <input
                            type="text"
                            name="ReturnLink"
                            value={editData.ReturnLink}
                            onChange={handleEditChange}
                            className="ayoCore-input"
                        />
                        <label className="ayoCore-label">Custom Alias</label>
                        <div className="ayoCore-alias-input-container">
                            <span className="ayoCore-alias-prefix">https://ayodev.de/register/</span>
                            <input
                                type="text"
                                value={customAlias}
                                onChange={handleAliasChange}
                                className="ayoCore-input ayoCore-alias-input"
                            />
                        </div>
                        {aliasError && <p className="ayoCore-error">{aliasError}</p>}
                        <button onClick={handleEditSubmit} className="ayoCore-save-button">Save</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserList;
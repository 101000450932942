import './HeadBar.css';
import LogOutButton from './LogOutButton';
import logo from '../assets/images/logo.gif';
import { useEffect, useState } from 'react';

const HeadBar = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {isMobile ? (
                <div className="mobile-headbar">
                    <img className="mobile-headbar-logo" src={logo} alt="Logo" />
                    <h1 className="mobile-headline">AYOBOTS</h1>
                    <LogOutButton />
                </div>
            ) : (
                <div className="dashboard-headbar">
                    <div className="dashboard-headbar-text">
                        <img className="dashboard-headbar-logo" src={logo} alt="Logo" />
                        <h1 className="dashboard-headline"><span className="dashboard-span-headline">AYOBOTS</span>Dashboard</h1>
                        <a href="/commands" className="dashboard-info">Commands</a>
                        <p className="dashboard-info">Info2</p>
                    </div>
                    <LogOutButton />
                </div>
            )}
        </>
    );
}

export default HeadBar;